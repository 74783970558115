.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.freeBox__zwmA0 {
  max-width: 1140px;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
}
.freeBox__aiSs5 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100vw;
  background: linear-gradient(#05050578, #05050578),
    url("./images/casofriCleanArtistWorkshopOfAByzantineHagiographerPlentEe3Dc471Debd4Ba5Ad9E11Eb4Cd584Fapng.png") top 50% right -13.16% / cover repeat;
}
.freeBox__aiSs5 > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__aiSs5 > :global(.__wab_flex-container) > *,
.freeBox__aiSs5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__aiSs5 > :global(.__wab_flex-container) > picture > img,
.freeBox__aiSs5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 32px;
}
.freeBox__l5Uy8 {
  max-width: 1380px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.header___5BPml:global(.__wab_instance) {
  width: 1300px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .header___5BPml:global(.__wab_instance) {
    width: 100%;
    min-width: 0;
  }
}
.freeBox__esV0S {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 200px;
  background: none;
  min-width: 0;
  padding: 70px 45px 128px;
}
.freeBox__esV0S > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 60px);
  height: calc(100% + 60px);
}
.freeBox__esV0S > :global(.__wab_flex-container) > *,
.freeBox__esV0S > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__esV0S > :global(.__wab_flex-container) > picture > img,
.freeBox__esV0S
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 60px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__esV0S {
    padding-bottom: 80px;
    padding-top: 30px;
  }
}
.h1___4ZjJm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 757px;
  padding-bottom: 0px;
  text-align: center;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h1___4ZjJm {
    font-size: 45px;
  }
}
.h1__eg1Xx {
  position: relative;
  width: 489px;
  height: auto;
  max-width: 757px;
  padding-bottom: 0px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h1__eg1Xx {
    font-size: 45px;
  }
}
.button:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  align-self: auto;
}
@media (min-width: 0px) and (max-width: 768px) {
  .button:global(.__wab_instance) {
    align-self: auto;
  }
}
.svg__rjnJl {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text__t0Spk {
  color: var(--token-X1jDnyWGByQLrV);
  font-weight: 600;
  letter-spacing: 0.05em;
  font-size: 24px;
  font-family: "Poppins", sans-serif;
}
@media (min-width: 0px) and (max-width: 768px) {
  .text__t0Spk {
    font-size: 20px;
  }
}
.svg__z0Mob {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 40px;
  height: 40px;
  margin-top: 0px;
  color: var(--token-X1jDnyWGByQLrV);
}
@media (min-width: 0px) and (max-width: 768px) {
  .svg__z0Mob {
    height: 35px;
    width: 30px;
  }
}
.svg__aj4Lv {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.freeBox__b1BaL {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 64px;
  margin-bottom: 64px;
  display: none;
}
.h2__mjH2 {
  position: relative;
}
.columns___2Zouw {
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  min-width: 0;
  display: none;
  padding: 8px 8px 72px;
}
.columns___2Zouw > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 50px);
  width: calc(100% + 50px);
  --plsmc-rc-col-gap: 50px;
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.columns___2Zouw > :global(.__wab_flex-container) > *,
.columns___2Zouw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns___2Zouw > :global(.__wab_flex-container) > picture > img,
.columns___2Zouw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 50px;
  margin-top: 32px;
}
.columns___2Zouw {
  --plsmc-rc-col-gap: 0px;
}
.columns___2Zouw > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns___2Zouw > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns___2Zouw > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns___2Zouw > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___2Zouw {
    flex-direction: row;
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___2Zouw > :global(.__wab_flex-container) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___2Zouw > :global(.__wab_flex-container) > *,
  .columns___2Zouw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns___2Zouw > :global(.__wab_flex-container) > picture > img,
  .columns___2Zouw
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___2Zouw > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__ap8Qp {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.productCard__wsNzy:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .productCard__wsNzy:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.img__spyS8 {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__spyS8 > picture > img {
  object-fit: cover;
}
.img__spyS8 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.ratings___3AlSz:global(.__wab_instance) {
  position: relative;
}
.column__cfNvI {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.productCard__lXzWf:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.img__mpQpa {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__mpQpa > picture > img {
  object-fit: cover;
}
.img__mpQpa > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.ratings__yv0K5:global(.__wab_instance) {
  position: relative;
}
.column___4ZeRf {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.productCard__dn1Fr:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.img__v26X {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__v26X > picture > img {
  object-fit: cover;
}
.img__v26X > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.ratings__zSzb:global(.__wab_instance) {
  position: relative;
}
.column__soe7J {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.productCard__jefE5:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.img___16Jo {
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img___16Jo > picture > img {
  object-fit: cover;
}
.img___16Jo > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.ratings__yGTec:global(.__wab_instance) {
  position: relative;
}
.featureItem:global(.__wab_instance) {
  position: relative;
  margin-top: 16px;
  margin-bottom: 64px;
  display: none;
}
.freeBox__dt2Tk {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 64px;
  display: none;
}
.text___32S2O {
  position: relative;
  color: var(--token-0DdnGTX5B6AWcm);
  font-size: 30px;
  font-weight: 500;
}
.columns__tRps {
  position: relative;
  flex-direction: row;
  margin-bottom: 64px;
  margin-top: 16px;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  min-width: 0;
  display: none;
  padding: 8px;
}
.columns__tRps > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 36px);
  width: calc(100% + 36px);
  --plsmc-rc-col-gap: 36px;
}
.columns__tRps > :global(.__wab_flex-container) > *,
.columns__tRps > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__tRps > :global(.__wab_flex-container) > picture > img,
.columns__tRps
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 36px;
}
.columns__tRps {
  --plsmc-rc-col-gap: 0px;
}
.columns__tRps > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__tRps > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__tRps > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__tRps > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__tRps {
    flex-direction: row;
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__tRps > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    --plsmc-rc-col-gap: 0px;
    margin-top: calc(0px - 10px);
    height: calc(100% + 10px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__tRps > :global(.__wab_flex-container) > *,
  .columns__tRps > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__tRps > :global(.__wab_flex-container) > picture > img,
  .columns__tRps
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 10px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__tRps > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___1HlEx {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.productCard__hgWxX:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.text__o3Nbj {
  height: auto;
}
.text___6M3Yd {
  height: auto;
}
.img__l9VCl {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__l9VCl > picture > img {
  object-fit: cover;
}
.img__l9VCl > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.ratings__irBa2:global(.__wab_instance) {
  position: relative;
}
.column__eMSo {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.productCard__w5RGh:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.img__qdUvA {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__qdUvA > picture > img {
  object-fit: cover;
}
.img__qdUvA > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.ratings__rljeo:global(.__wab_instance) {
  position: relative;
}
.column__kLmNh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.productCard__wuryt:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.img__c4TyI {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__c4TyI > picture > img {
  object-fit: cover;
}
.img__c4TyI > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.ratings__jisZp:global(.__wab_instance) {
  position: relative;
}
.column__etL7T {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.productCard__upyWg:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .productCard__upyWg:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.img___6KGk8 {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img___6KGk8 > picture > img {
  object-fit: cover;
}
.img___6KGk8 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.ratings___3S1N0:global(.__wab_instance) {
  position: relative;
}
.freeBox__c9RD {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  display: none;
}
.text__uGztc {
  position: relative;
  color: var(--token-0DdnGTX5B6AWcm);
  font-size: 30px;
  font-weight: 500;
}
.columns__xAQot {
  position: relative;
  flex-direction: row;
  margin-bottom: 64px;
  margin-top: 16px;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  min-width: 0;
  display: none;
  padding: 8px;
}
.columns__xAQot > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 32px);
  width: calc(100% + 32px);
  --plsmc-rc-col-gap: 32px;
}
.columns__xAQot > :global(.__wab_flex-container) > *,
.columns__xAQot > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__xAQot > :global(.__wab_flex-container) > picture > img,
.columns__xAQot
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 32px;
}
.columns__xAQot {
  --plsmc-rc-col-gap: 0px;
}
.columns__xAQot > :global(.__wab_flex-container) > :nth-child(3n + 1) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__xAQot > :global(.__wab_flex-container) > :nth-child(3n + 2) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
.columns__xAQot > :global(.__wab_flex-container) > :nth-child(3n + 3) {
  width: calc((100% - 3 * var(--plsmc-rc-col-gap, 0px)) * 4 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__xAQot {
    flex-direction: row;
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__xAQot > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__xAQot > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__dy5Pt {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.blogPost__rHsgK:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.img__bxlL0 {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__bxlL0 > picture > img {
  object-fit: cover;
}
.img__bxlL0 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__xQgl {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.blogPost__jxZlh:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.img__p7KrH {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__p7KrH > picture > img {
  object-fit: cover;
}
.img__p7KrH > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.column__azDy9 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.blogPost__czBhp:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.img__ysSth {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__ysSth > picture > img {
  object-fit: cover;
}
.img__ysSth > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox__tkq6D {
  position: relative;
  flex-direction: row;
  width: 100vw;
  height: auto;
  overflow: auto;
  background: var(--token-kvivt7Cu3craYW);
  display: none;
  padding: 48px;
}
.freeBox__tkq6D > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: calc(0px - 64px);
  width: calc(100% + 64px);
  margin-top: calc(0px - 32px);
  height: calc(100% + 32px);
}
.freeBox__tkq6D > :global(.__wab_flex-container) > *,
.freeBox__tkq6D > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tkq6D > :global(.__wab_flex-container) > picture > img,
.freeBox__tkq6D
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 64px;
  margin-top: 32px;
}
.img__akiwr {
  position: relative;
  object-fit: cover;
  width: 90px;
  height: 60px;
  flex-shrink: 0;
}
.img__akiwr > picture > img {
  object-fit: cover;
}
.img__akiwr > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img___6ON0I {
  position: relative;
  object-fit: cover;
  width: 91px;
  height: 60px;
  flex-shrink: 0;
}
.img___6ON0I > picture > img {
  object-fit: cover;
}
.img___6ON0I > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__gM7Sb {
  position: relative;
  object-fit: cover;
  width: 98px;
  height: 60px;
  flex-shrink: 0;
}
.img__gM7Sb > picture > img {
  object-fit: cover;
}
.img__gM7Sb > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__dwNiG {
  position: relative;
  object-fit: cover;
  width: 124px;
  height: 60px;
  flex-shrink: 0;
}
.img__dwNiG > picture > img {
  object-fit: cover;
}
.img__dwNiG > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__rnYe {
  position: relative;
  object-fit: cover;
  width: 125px;
  height: 60px;
  flex-shrink: 0;
}
.img__rnYe > picture > img {
  object-fit: cover;
}
.img__rnYe > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.footer__pTkIp:global(.__wab_instance) {
  position: relative;
  display: none;
}
.bestSellers {
  max-width: 1300px;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  margin-top: -202px;
  padding-bottom: 80px;
  min-width: 0;
}
.bestSellers > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.bestSellers > :global(.__wab_flex-container) > *,
.bestSellers > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bestSellers > :global(.__wab_flex-container) > picture > img,
.bestSellers
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .bestSellers {
    padding-bottom: 60px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.freeBox__jKg5Z {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBox__jKg5Z > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 0;
  margin-left: calc(0px - 16px);
  width: calc(100% + 16px);
}
.freeBox__jKg5Z > :global(.__wab_flex-container) > *,
.freeBox__jKg5Z > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jKg5Z > :global(.__wab_flex-container) > picture > img,
.freeBox__jKg5Z
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 16px;
}
.svg__r8Awh {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: auto;
  width: 35px;
  height: 35px;
  color: #f8f9fd;
  flex-shrink: 0;
}
.h2__tLqnq {
  position: relative;
  width: auto;
  height: auto;
  color: var(--token-L89ExH6ks);
}
.productRow:global(.__wab_instance) {
  position: relative;
}
.bigPlants {
  max-width: 1300px;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding-bottom: 80px;
  min-width: 0;
  margin: 0px;
}
.bigPlants > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.bigPlants > :global(.__wab_flex-container) > *,
.bigPlants > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.bigPlants > :global(.__wab_flex-container) > picture > img,
.bigPlants
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .bigPlants {
    padding-bottom: 60px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.columns__yoLoV {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  padding: 8px;
}
.columns__yoLoV > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 15px);
  width: calc(100% + 15px);
  --plsmc-rc-col-gap: 15px;
}
.columns__yoLoV > :global(.__wab_flex-container) > *,
.columns__yoLoV > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__yoLoV > :global(.__wab_flex-container) > picture > img,
.columns__yoLoV
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 15px;
}
.columns__yoLoV {
  --plsmc-rc-col-gap: 0px;
}
.columns__yoLoV > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__yoLoV > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__yoLoV > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns__yoLoV > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__yoLoV {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__yoLoV > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 15px);
    height: calc(100% + 15px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__yoLoV > :global(.__wab_flex-container) > *,
  .columns__yoLoV > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__yoLoV > :global(.__wab_flex-container) > picture > img,
  .columns__yoLoV
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 15px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns__yoLoV > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__gTUg {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__gTUg > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__gTUg > :global(.__wab_flex-container) > *,
.column__gTUg > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__gTUg > :global(.__wab_flex-container) > picture > img,
.column__gTUg
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__d7FZl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: 50% -4px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__d7FZl > picture > img {
  object-fit: cover;
  object-position: 50% -4px;
}
.img__d7FZl > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% -4px;
}
.freeBox__o7YtA {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__rvoLa {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__ey60C {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.column__h2Ibm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__h2Ibm > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__h2Ibm > :global(.__wab_flex-container) > *,
.column__h2Ibm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__h2Ibm > :global(.__wab_flex-container) > picture > img,
.column__h2Ibm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__bvu9O {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: 0px -20px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__bvu9O > picture > img {
  object-fit: cover;
  object-position: 0px -20px;
}
.img__bvu9O > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 0px -20px;
}
.freeBox__cSe1E {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__et4Lf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__sGrH2 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.column__gJh8Z {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__gJh8Z > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__gJh8Z > :global(.__wab_flex-container) > *,
.column__gJh8Z > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__gJh8Z > :global(.__wab_flex-container) > picture > img,
.column__gJh8Z
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__m2EAx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: 50% -5px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__m2EAx > picture > img {
  object-fit: cover;
  object-position: 50% -5px;
}
.img__m2EAx > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% -5px;
}
.freeBox__nir0Z {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__j4SAw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__oeQdz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.column__nDdUw {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__nDdUw > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__nDdUw > :global(.__wab_flex-container) > *,
.column__nDdUw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__nDdUw > :global(.__wab_flex-container) > picture > img,
.column__nDdUw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__bmhcw {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: 50% -27px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__bmhcw > picture > img {
  object-fit: cover;
  object-position: 50% -27px;
}
.img__bmhcw > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% -27px;
}
.freeBox__v3OjI {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__se12A {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__kN1 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.cactuses {
  max-width: 1300px;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding-bottom: 80px;
  min-width: 0;
  margin: 0px;
}
.cactuses > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.cactuses > :global(.__wab_flex-container) > *,
.cactuses > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.cactuses > :global(.__wab_flex-container) > picture > img,
.cactuses
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .cactuses {
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }
}
.columns___6X8MV {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  flex-direction: row;
  min-width: 0;
  padding: 8px;
}
.columns___6X8MV > :global(.__wab_flex-container) {
  align-items: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  margin-left: calc(0px - 15px);
  width: calc(100% + 15px);
  --plsmc-rc-col-gap: 15px;
}
.columns___6X8MV > :global(.__wab_flex-container) > *,
.columns___6X8MV > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns___6X8MV > :global(.__wab_flex-container) > picture > img,
.columns___6X8MV
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 15px;
}
.columns___6X8MV {
  --plsmc-rc-col-gap: 0px;
}
.columns___6X8MV > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns___6X8MV > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns___6X8MV > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.columns___6X8MV > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___6X8MV {
    width: 100%;
    height: auto;
    flex-direction: row;
    padding-bottom: 0px;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___6X8MV > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 15px);
    height: calc(100% + 15px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___6X8MV > :global(.__wab_flex-container) > *,
  .columns___6X8MV > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns___6X8MV > :global(.__wab_flex-container) > picture > img,
  .columns___6X8MV
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 15px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .columns___6X8MV > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column__q4D07 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__q4D07 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__q4D07 > :global(.__wab_flex-container) > *,
.column__q4D07 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__q4D07 > :global(.__wab_flex-container) > picture > img,
.column__q4D07
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__slXib {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: 50% -21px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__slXib > picture > img {
  object-fit: cover;
  object-position: 50% -21px;
}
.img__slXib > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% -21px;
}
.freeBox__yR8Ib {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__dMg2N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__xraec {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.column__zEbZp {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__zEbZp > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__zEbZp > :global(.__wab_flex-container) > *,
.column__zEbZp > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__zEbZp > :global(.__wab_flex-container) > picture > img,
.column__zEbZp
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__kgBg1 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: 50% -11px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__kgBg1 > picture > img {
  object-fit: cover;
  object-position: 50% -11px;
}
.img__kgBg1 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% -11px;
}
.freeBox__cQphW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__za8Hr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__zJdP7 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.column__zSiId {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__zSiId > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__zSiId > :global(.__wab_flex-container) > *,
.column__zSiId > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__zSiId > :global(.__wab_flex-container) > picture > img,
.column__zSiId
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img___88Dvm {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: 50% -2px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img___88Dvm > picture > img {
  object-fit: cover;
  object-position: 50% -2px;
}
.img___88Dvm > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% -2px;
}
.freeBox__wNwXi {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__qXazo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__wUwYv {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.column__pGzZm {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__pGzZm > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__pGzZm > :global(.__wab_flex-container) > *,
.column__pGzZm > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__pGzZm > :global(.__wab_flex-container) > picture > img,
.column__pGzZm
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img___1X8Qa {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img___1X8Qa > picture > img {
  object-fit: cover;
}
.img___1X8Qa > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox__eFvvF {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__dkcnp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__l2Ct {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.footer___7PVj2:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
