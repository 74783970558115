.root {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  background: #384647;
  min-width: 0;
  min-height: 0;
}
.freeBox___6LVLh {
  display: flex;
  position: relative;
  flex-direction: row;
  left: auto;
  top: auto;
  width: 100%;
  height: auto;
  min-width: 0;
  padding: 0px;
}
.freeBox___6LVLh > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
}
.freeBox___6LVLh > :global(.__wab_flex-container) > *,
.freeBox___6LVLh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___6LVLh > :global(.__wab_flex-container) > picture > img,
.freeBox___6LVLh
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___6LVLh {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___6LVLh > :global(.__wab_flex-container) {
    flex-direction: column;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___6LVLh > :global(.__wab_flex-container) > *,
  .freeBox___6LVLh > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox___6LVLh > :global(.__wab_flex-container) > picture > img,
  .freeBox___6LVLh
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.freeBox___8Zwbj {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-top: -2px;
  min-width: 0;
}
.freeBox___8Zwbj > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox___8Zwbj > :global(.__wab_flex-container) > *,
.freeBox___8Zwbj > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___8Zwbj > :global(.__wab_flex-container) > picture > img,
.freeBox___8Zwbj
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox___8Zwbj {
    margin-top: calc(-2px + 0px) !important;
  }
}
.img__r7ZH {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  margin-top: calc(-60px + 24px) !important;
  margin-bottom: 60px;
}
.img__r7ZH > picture > img {
  object-fit: cover;
}
.img__r7ZH > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__r7ZH {
    margin-top: calc(0px + 24px) !important;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
}
.freeBox__jxPtq {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  background: var(--token-XfbaVrNfhmJh5H);
  min-width: 0;
  padding: 80px;
}
.freeBox__jxPtq > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.freeBox__jxPtq > :global(.__wab_flex-container) > *,
.freeBox__jxPtq > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__jxPtq > :global(.__wab_flex-container) > picture > img,
.freeBox__jxPtq
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__jxPtq {
    background: var(--token-XfbaVrNfhmJh5H);
    padding: 70px 30px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__jxPtq > :global(.__wab_flex-container) {
    justify-content: flex-start;
    align-items: center;
  }
}
.h1__sDsMi {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 757px;
  padding-bottom: 0px;
  font-size: 48px;
  text-align: left;
  padding-left: 0px;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h1__sDsMi {
    padding-top: 0px;
    font-size: 30px;
    text-align: left;
    font-weight: 600;
    align-self: flex-start;
  }
}
.h1__aX7D0 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 757px;
  padding-bottom: 0px;
  font-size: 24px;
  text-align: left;
  padding-left: 0px;
  font-weight: 300;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h1__aX7D0 {
    padding-top: 0px;
    font-size: 30px;
    text-align: left;
    font-weight: 600;
  }
}
.h2 {
  position: relative;
  width: auto;
  height: auto;
  color: var(--token-X1jDnyWGByQLrV);
}
@media (min-width: 0px) and (max-width: 768px) {
  .h2 {
    font-size: 25px;
  }
}
.h1__mf0M {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 757px;
  padding-bottom: 0px;
  font-size: 24px;
  text-align: left;
  padding-left: 0px;
  font-weight: 300;
  min-width: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h1__mf0M {
    padding-top: 0px;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
  }
}
.freeBox__sl695 {
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 0px;
  background: linear-gradient(#000000b5, #000000b5),
    url("./images/casofriCleanArtistWorkshopOfAByzantineHagiographerPlentEe3Dc471Debd4Ba5Ad9E11Eb4Cd584Fapng.png") top 50% left 50% / cover repeat;
  min-width: 0;
  padding: 8px 0px 0px;
}
.freeBox__sl695 > :global(.__wab_flex-container) {
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
}
.freeBox__sl695 > :global(.__wab_flex-container) > *,
.freeBox__sl695 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__sl695 > :global(.__wab_flex-container) > picture > img,
.freeBox__sl695
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__sl695 {
    display: flex;
    width: 100%;
    height: auto;
    margin-top: 0px;
    min-width: 0;
    padding: 16px 5px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__sl695 > :global(.__wab_flex-container) {
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-width: 0;
    margin-left: calc(0px - 5px);
    width: calc(100% + 5px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__sl695 > :global(.__wab_flex-container) > *,
  .freeBox__sl695 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__sl695 > :global(.__wab_flex-container) > picture > img,
  .freeBox__sl695
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 5px;
  }
}
.menuButton:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .menuButton:global(.__wab_instance) {
    position: relative;
    flex-shrink: 0;
    display: none;
  }
}
.freeBox__dGv4T {
  display: flex;
  position: relative;
  flex-direction: row;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.freeBox__dGv4T > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.freeBox__dGv4T > :global(.__wab_flex-container) > *,
.freeBox__dGv4T > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__dGv4T > :global(.__wab_flex-container) > picture > img,
.freeBox__dGv4T
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__dGv4T {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__dGv4T > :global(.__wab_flex-container) {
    flex-wrap: wrap;
    align-items: stretch;
    align-content: flex-start;
    justify-content: center;
    min-width: 0;
  }
}
.img__rJdsX {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 112px;
  flex-shrink: 0;
}
.img__rJdsX > picture > img {
  object-fit: cover;
}
.img__rJdsX > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.h6 {
  color: var(--token-X1jDnyWGByQLrV);
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 400;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .h6 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--token-X1jDnyWGByQLrV);
    text-align: center;
    padding: 29px;
  }
}
.link {
  color: var(--token-X1jDnyWGByQLrV);
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  padding: 16px 32px 0px 0px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--token-X1jDnyWGByQLrV);
    text-align: center;
    align-self: center;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: calc(144px + 5px) !important;
    padding-bottom: 16px;
  }
}
