.root {
  display: flex;
  width: 100%;
  height: 400px;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  max-width: 360px;
  background: var(--token-X1jDnyWGByQLrV);
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 5px;
}
.rootlong {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 0;
}
.rootlong > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.rootlong > :global(.__wab_flex-container) > *,
.rootlong > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rootlong > :global(.__wab_flex-container) > picture > img,
.rootlong
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
  margin-top: 0px;
}
.freeBox__kvDuE {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 250px;
  min-width: 0;
  flex-shrink: 0;
}
.freeBoxlong__kvDuEbFO6 {
  display: none;
}
.img__kiEVw {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__kiEVw > picture > img {
  object-fit: cover;
}
.img__kiEVw > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox__nWCnE {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__nWCnE > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 5px);
  height: calc(100% + 5px);
}
.freeBox__nWCnE > :global(.__wab_flex-container) > *,
.freeBox__nWCnE > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nWCnE > :global(.__wab_flex-container) > picture > img,
.freeBox__nWCnE
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 5px;
}
.freeBoxlong__nWCnEbFO6 > :global(.__wab_flex-container) {
  align-items: flex-start;
}
.slotTargetTitle {
  font-weight: 600;
  text-align: left;
}
.slotTargetAuthor {
  color: #bcb7b2;
  font-size: 14px;
}
.slotTargetDescription {
  color: var(--token-XfbaVrNfhmJh5H);
  text-align: left;
  font-size: 13px;
}
.slotTargetDescriptionlong {
  text-align: left;
}
