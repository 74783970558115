.root {
  display: flex;
  width: 168px;
  height: 58px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.roottype_solidOrange {
  background: rgb(233, 156, 46);
}
.roottype_solidGray {
  background: var(--token-wGAcjH33JC5EDB);
}
.roottype_outlineGray {
  border: 1px solid var(--token-wGAcjH33JC5EDB);
}
.rootsize_small {
  width: 100px;
  height: 48px;
}
.roottype_solidOrange:hover {
  background: var(--token-maWCPk1v_oCK);
  border: 1px solid rgb(233, 156, 46);
}
.roottype_solidGray:hover {
  background: var(--token-maWCPk1v_oCK);
  border: 1px solid var(--token-wGAcjH33JC5EDB);
}
.roottype_blankOrange:hover {
  border: 1px solid rgb(233, 156, 46);
}
.roottype_outlineGray:hover {
  background: var(--token-wGAcjH33JC5EDB);
}
.slotTargetText {
  font-weight: 700;
}
.slotTargetTexttype_solidOrange {
  color: var(--token-maWCPk1v_oCK);
}
.slotTargetTexttype_solidGray {
  color: var(--token-X1jDnyWGByQLrV);
}
.slotTargetTexttype_blankOrange {
  color: rgb(233, 156, 46);
}
.slotTargetTexttype_outlineGray {
  color: var(--token-0DdnGTX5B6AWcm);
}
.slotTargetTexttype_blankGray {
  color: var(--token-0DdnGTX5B6AWcm);
}
.roottype_solidOrange:hover .slotTargetTexttype_solidOrange {
  color: rgb(233, 156, 46);
}
.roottype_solidGray:hover .slotTargetTexttype_solidGray {
  color: var(--token-0DdnGTX5B6AWcm);
}
.roottype_outlineGray:hover .slotTargetTexttype_outlineGray {
  color: var(--token-X1jDnyWGByQLrV);
}
.roottype_blankGray:hover .slotTargetTexttype_blankGray {
  color: rgb(233, 156, 46);
}
