@import url("https://fonts.googleapis.com/css2?family=Montserrat%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Open+Sans%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Poppins%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&display=swap");

.plasmic_tokens {
  --token-XfbaVrNfhmJh5H: #384647;
  --plasmic-token-text: var(--token-XfbaVrNfhmJh5H);
  --token-0DdnGTX5B6AWcm: rgb(97, 96, 96);
  --plasmic-token-heading: var(--token-0DdnGTX5B6AWcm);
  --token-wGAcjH33JC5EDB: rgb(95, 91, 87);
  --plasmic-token-border: var(--token-wGAcjH33JC5EDB);
  --token-kvivt7Cu3craYW: #f7f7f7;
  --plasmic-token-background: var(--token-kvivt7Cu3craYW);
  --token-X1jDnyWGByQLrV: rgb(255, 255, 255);
  --plasmic-token-white: var(--token-X1jDnyWGByQLrV);
  --token-bdaH5fDuClTG4h: rgb(153, 141, 128);
  --plasmic-token-preheader: var(--token-bdaH5fDuClTG4h);
  --token-0u6JY7pvjlO5TD: #88dd9b;
  --plasmic-token-green: var(--token-0u6JY7pvjlO5TD);
  --token-JPSChAP4VETRli: 1.5;
  --plasmic-token-unnamed-style-token-2: var(--token-JPSChAP4VETRli);
  --token-h6aVhKAyca-qac: #273132;
  --plasmic-token-heading-2: var(--token-h6aVhKAyca-qac);
  --token-y3I9R5KEKXPofC: #6b797a;
  --plasmic-token-heading-3: var(--token-y3I9R5KEKXPofC);
  --token-PE1QFaZ5ZFDg60: 270px;
  --plasmic-token-product-height: var(--token-PE1QFaZ5ZFDg60);
  --token-UWv7nRxXlYTZwh: #384647;
  --plasmic-token-header-meneu-color: var(--token-UWv7nRxXlYTZwh);
  --token-CTonWgA0SI07LL: 749px;
  --plasmic-token-about-img: var(--token-CTonWgA0SI07LL);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-659W7ZCPm7juAB_font-family: "Montserrat", sans-serif;
  --mixin-659W7ZCPm7juAB_font-size: 16px;
  --mixin-659W7ZCPm7juAB_font-weight: 300;
  --mixin-659W7ZCPm7juAB_font-style: normal;
  --mixin-659W7ZCPm7juAB_color: #535353;
  --mixin-659W7ZCPm7juAB_text-align: left;
  --mixin-659W7ZCPm7juAB_text-transform: none;
  --mixin-659W7ZCPm7juAB_line-height: 1.7;
  --mixin-659W7ZCPm7juAB_letter-spacing: 0.2px;
  --mixin-659W7ZCPm7juAB_white-space: pre-wrap;
  --mixin-659W7ZCPm7juAB_user-select: text;
  --mixin-659W7ZCPm7juAB_text-decoration-line: none;
  --mixin-659W7ZCPm7juAB_text-overflow: clip;
  --mixin-FzRpU4vnbD5Chn_color: var(--token-X1jDnyWGByQLrV);
  --mixin-FzRpU4vnbD5Chn_font-family: "Open Sans", sans-serif;
  --mixin-FzRpU4vnbD5Chn_font-weight: 700;
  --mixin-FzRpU4vnbD5Chn_font-size: 64px;
  --mixin-FzRpU4vnbD5Chn_line-height: 1.1;
  --mixin-FzRpU4vnbD5Chn_white-space: pre-wrap;
  --mixin-y1JLZHKzfDVXSM_color: var(--token-6-9k9VEZ-);
  --mixin-y1JLZHKzfDVXSM_font-family: "Open Sans", sans-serif;
  --mixin-y1JLZHKzfDVXSM_font-weight: 600;
  --mixin-y1JLZHKzfDVXSM_font-size: 32px;
  --mixin-y1JLZHKzfDVXSM_letter-spacing: -1px;
  --mixin-y1JLZHKzfDVXSM_white-space: pre-wrap;
  --mixin-4qLPJItSD8wHSk_font-size: 32px;
  --mixin-4qLPJItSD8wHSk_white-space: pre-wrap;
  --mixin-Ohjz1-EKr0lwJ0_font-family: "Inter", sans-serif;
  --mixin-Ohjz1-EKr0lwJ0_color: #000000;
  --mixin-Ohjz1-EKr0lwJ0_font-size: 16px;
  --mixin-Ohjz1-EKr0lwJ0_font-weight: 600;
  --mixin-Ohjz1-EKr0lwJ0_line-height: 1.5;
  --mixin-Ohjz1-EKr0lwJ0_white-space: pre-wrap;
  --mixin-H0DCgNsQgruK05_font-size: 24px;
  --mixin-H0DCgNsQgruK05_color: var(--token-NEon4Y--Z);
  --mixin-H0DCgNsQgruK05_font-weight: 600;
  --mixin-H0DCgNsQgruK05_font-family: "Montserrat", sans-serif;
  --mixin-H0DCgNsQgruK05_white-space: pre-wrap;
  --mixin-NdRDspnzCZRcDx_font-size: 16px;
  --mixin-NdRDspnzCZRcDx_color: var(--token-h6aVhKAyca-qac);
  --mixin-NdRDspnzCZRcDx_font-weight: 600;
  --mixin-NdRDspnzCZRcDx_font-family: "Montserrat", sans-serif;
  --mixin-NdRDspnzCZRcDx_white-space: pre-wrap;
  --mixin--pBsQ0nVBaEdtX_font-size: 24px;
  --mixin--pBsQ0nVBaEdtX_color: #384647;
  --mixin--pBsQ0nVBaEdtX_font-family: "Open Sans", sans-serif;
  --mixin--pBsQ0nVBaEdtX_font-weight: 700;
  --mixin--pBsQ0nVBaEdtX_white-space: pre-wrap;
  --mixin-lWJ2l96hdMMWze_border-left-color: #dddddd;
  --mixin-lWJ2l96hdMMWze_border-left-style: solid;
  --mixin-lWJ2l96hdMMWze_border-left-width: 3px;
  --mixin-lWJ2l96hdMMWze_color: #888888;
  --mixin-lWJ2l96hdMMWze_padding-left: 10px;
  --mixin-lWJ2l96hdMMWze_white-space: pre-wrap;
  --mixin-50gWQi7Ztf5XKp_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-50gWQi7Ztf5XKp_border-bottom-color: #dddddd;
  --mixin-50gWQi7Ztf5XKp_border-bottom-style: solid;
  --mixin-50gWQi7Ztf5XKp_border-bottom-width: 1px;
  --mixin-50gWQi7Ztf5XKp_border-left-color: #dddddd;
  --mixin-50gWQi7Ztf5XKp_border-left-style: solid;
  --mixin-50gWQi7Ztf5XKp_border-left-width: 1px;
  --mixin-50gWQi7Ztf5XKp_border-right-color: #dddddd;
  --mixin-50gWQi7Ztf5XKp_border-right-style: solid;
  --mixin-50gWQi7Ztf5XKp_border-right-width: 1px;
  --mixin-50gWQi7Ztf5XKp_border-top-color: #dddddd;
  --mixin-50gWQi7Ztf5XKp_border-top-style: solid;
  --mixin-50gWQi7Ztf5XKp_border-top-width: 1px;
  --mixin-50gWQi7Ztf5XKp_border-bottom-left-radius: 3px;
  --mixin-50gWQi7Ztf5XKp_border-bottom-right-radius: 3px;
  --mixin-50gWQi7Ztf5XKp_border-top-left-radius: 3px;
  --mixin-50gWQi7Ztf5XKp_border-top-right-radius: 3px;
  --mixin-50gWQi7Ztf5XKp_font-family: "Inconsolata";
  --mixin-50gWQi7Ztf5XKp_padding-bottom: 1px;
  --mixin-50gWQi7Ztf5XKp_padding-left: 4px;
  --mixin-50gWQi7Ztf5XKp_padding-right: 4px;
  --mixin-50gWQi7Ztf5XKp_padding-top: 1px;
  --mixin-50gWQi7Ztf5XKp_white-space: pre-wrap;
  --mixin-gH4lg7X1xmvpKC_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-gH4lg7X1xmvpKC_border-bottom-color: #dddddd;
  --mixin-gH4lg7X1xmvpKC_border-bottom-style: solid;
  --mixin-gH4lg7X1xmvpKC_border-bottom-width: 1px;
  --mixin-gH4lg7X1xmvpKC_border-left-color: #dddddd;
  --mixin-gH4lg7X1xmvpKC_border-left-style: solid;
  --mixin-gH4lg7X1xmvpKC_border-left-width: 1px;
  --mixin-gH4lg7X1xmvpKC_border-right-color: #dddddd;
  --mixin-gH4lg7X1xmvpKC_border-right-style: solid;
  --mixin-gH4lg7X1xmvpKC_border-right-width: 1px;
  --mixin-gH4lg7X1xmvpKC_border-top-color: #dddddd;
  --mixin-gH4lg7X1xmvpKC_border-top-style: solid;
  --mixin-gH4lg7X1xmvpKC_border-top-width: 1px;
  --mixin-gH4lg7X1xmvpKC_border-bottom-left-radius: 3px;
  --mixin-gH4lg7X1xmvpKC_border-bottom-right-radius: 3px;
  --mixin-gH4lg7X1xmvpKC_border-top-left-radius: 3px;
  --mixin-gH4lg7X1xmvpKC_border-top-right-radius: 3px;
  --mixin-gH4lg7X1xmvpKC_font-family: "Inconsolata";
  --mixin-gH4lg7X1xmvpKC_padding-bottom: 3px;
  --mixin-gH4lg7X1xmvpKC_padding-left: 6px;
  --mixin-gH4lg7X1xmvpKC_padding-right: 6px;
  --mixin-gH4lg7X1xmvpKC_padding-top: 3px;
  --mixin-gH4lg7X1xmvpKC_white-space: pre-wrap;
  --mixin-7aHc9CCximODMV_display: flex;
  --mixin-7aHc9CCximODMV_flex-direction: column;
  --mixin-7aHc9CCximODMV_align-items: stretch;
  --mixin-7aHc9CCximODMV_justify-content: flex-start;
  --mixin-7aHc9CCximODMV_list-style-position: outside;
  --mixin-7aHc9CCximODMV_padding-left: 40px;
  --mixin-7aHc9CCximODMV_position: relative;
  --mixin-7aHc9CCximODMV_list-style-type: decimal;
  --mixin-7aHc9CCximODMV_white-space: pre-wrap;
  --mixin-rmFF2_dhZIzKZJ_display: flex;
  --mixin-rmFF2_dhZIzKZJ_flex-direction: column;
  --mixin-rmFF2_dhZIzKZJ_align-items: stretch;
  --mixin-rmFF2_dhZIzKZJ_justify-content: flex-start;
  --mixin-rmFF2_dhZIzKZJ_list-style-position: outside;
  --mixin-rmFF2_dhZIzKZJ_padding-left: 40px;
  --mixin-rmFF2_dhZIzKZJ_position: relative;
  --mixin-rmFF2_dhZIzKZJ_list-style-type: disc;
  --mixin-rmFF2_dhZIzKZJ_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-659W7ZCPm7juAB_font-family);
  font-size: var(--mixin-659W7ZCPm7juAB_font-size);
  font-weight: var(--mixin-659W7ZCPm7juAB_font-weight);
  font-style: var(--mixin-659W7ZCPm7juAB_font-style);
  color: var(--mixin-659W7ZCPm7juAB_color);
  text-align: var(--mixin-659W7ZCPm7juAB_text-align);
  text-transform: var(--mixin-659W7ZCPm7juAB_text-transform);
  line-height: var(--mixin-659W7ZCPm7juAB_line-height);
  letter-spacing: var(--mixin-659W7ZCPm7juAB_letter-spacing);
  white-space: var(--mixin-659W7ZCPm7juAB_white-space);
}

:where(.root_reset) h1:where(.h1),
h1:where(.root_reset.h1),
:where(.root_reset .__wab_expr_html_text) h1,
:where(.root_reset_tags) h1,
h1:where(.root_reset_tags) {
  color: var(--mixin-FzRpU4vnbD5Chn_color);
  font-family: var(--mixin-FzRpU4vnbD5Chn_font-family);
  font-weight: var(--mixin-FzRpU4vnbD5Chn_font-weight);
  font-size: var(--mixin-FzRpU4vnbD5Chn_font-size);
  line-height: var(--mixin-FzRpU4vnbD5Chn_line-height);
}

:where(.root_reset) h2:where(.h2),
h2:where(.root_reset.h2),
:where(.root_reset .__wab_expr_html_text) h2,
:where(.root_reset_tags) h2,
h2:where(.root_reset_tags) {
  color: var(--mixin-y1JLZHKzfDVXSM_color);
  font-family: var(--mixin-y1JLZHKzfDVXSM_font-family);
  font-weight: var(--mixin-y1JLZHKzfDVXSM_font-weight);
  font-size: var(--mixin-y1JLZHKzfDVXSM_font-size);
  letter-spacing: var(--mixin-y1JLZHKzfDVXSM_letter-spacing);
}

:where(.root_reset) h3:where(.h3),
h3:where(.root_reset.h3),
:where(.root_reset .__wab_expr_html_text) h3,
:where(.root_reset_tags) h3,
h3:where(.root_reset_tags) {
  font-size: var(--mixin-4qLPJItSD8wHSk_font-size);
}

:where(.root_reset) h6:where(.h6),
h6:where(.root_reset.h6),
:where(.root_reset .__wab_expr_html_text) h6,
:where(.root_reset_tags) h6,
h6:where(.root_reset_tags) {
  font-family: var(--mixin-Ohjz1-EKr0lwJ0_font-family);
  color: var(--mixin-Ohjz1-EKr0lwJ0_color);
  font-size: var(--mixin-Ohjz1-EKr0lwJ0_font-size);
  font-weight: var(--mixin-Ohjz1-EKr0lwJ0_font-weight);
  line-height: var(--mixin-Ohjz1-EKr0lwJ0_line-height);
}

:where(.root_reset) h5:where(.h5),
h5:where(.root_reset.h5),
:where(.root_reset .__wab_expr_html_text) h5,
:where(.root_reset_tags) h5,
h5:where(.root_reset_tags) {
  font-size: var(--mixin-H0DCgNsQgruK05_font-size);
  color: var(--mixin-H0DCgNsQgruK05_color);
  font-weight: var(--mixin-H0DCgNsQgruK05_font-weight);
  font-family: var(--mixin-H0DCgNsQgruK05_font-family);
}

:where(.root_reset) a:where(.a),
a:where(.root_reset.a),
:where(.root_reset .__wab_expr_html_text) a,
:where(.root_reset_tags) a,
a:where(.root_reset_tags) {
  font-size: var(--mixin-NdRDspnzCZRcDx_font-size);
  color: var(--mixin-NdRDspnzCZRcDx_color);
  font-weight: var(--mixin-NdRDspnzCZRcDx_font-weight);
  font-family: var(--mixin-NdRDspnzCZRcDx_font-family);
}

:where(.root_reset) h4:where(.h4),
h4:where(.root_reset.h4),
:where(.root_reset .__wab_expr_html_text) h4,
:where(.root_reset_tags) h4,
h4:where(.root_reset_tags) {
  font-size: var(--mixin--pBsQ0nVBaEdtX_font-size);
  color: var(--mixin--pBsQ0nVBaEdtX_color);
  font-family: var(--mixin--pBsQ0nVBaEdtX_font-family);
  font-weight: var(--mixin--pBsQ0nVBaEdtX_font-weight);
}

:where(.root_reset) blockquote:where(.blockquote),
blockquote:where(.root_reset.blockquote),
:where(.root_reset .__wab_expr_html_text) blockquote,
:where(.root_reset_tags) blockquote,
blockquote:where(.root_reset_tags) {
  color: var(--mixin-lWJ2l96hdMMWze_color);
  padding-left: var(--mixin-lWJ2l96hdMMWze_padding-left);
  border-left: var(--mixin-lWJ2l96hdMMWze_border-left-width)
    var(--mixin-lWJ2l96hdMMWze_border-left-style)
    var(--mixin-lWJ2l96hdMMWze_border-left-color);
}

:where(.root_reset) code:where(.code),
code:where(.root_reset.code),
:where(.root_reset .__wab_expr_html_text) code,
:where(.root_reset_tags) code,
code:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-50gWQi7Ztf5XKp_font-family);
  border-radius: var(--mixin-50gWQi7Ztf5XKp_border-top-left-radius)
    var(--mixin-50gWQi7Ztf5XKp_border-top-right-radius)
    var(--mixin-50gWQi7Ztf5XKp_border-bottom-right-radius)
    var(--mixin-50gWQi7Ztf5XKp_border-bottom-left-radius);
  padding: var(--mixin-50gWQi7Ztf5XKp_padding-top)
    var(--mixin-50gWQi7Ztf5XKp_padding-right)
    var(--mixin-50gWQi7Ztf5XKp_padding-bottom)
    var(--mixin-50gWQi7Ztf5XKp_padding-left);
  border-top: var(--mixin-50gWQi7Ztf5XKp_border-top-width)
    var(--mixin-50gWQi7Ztf5XKp_border-top-style)
    var(--mixin-50gWQi7Ztf5XKp_border-top-color);
  border-right: var(--mixin-50gWQi7Ztf5XKp_border-right-width)
    var(--mixin-50gWQi7Ztf5XKp_border-right-style)
    var(--mixin-50gWQi7Ztf5XKp_border-right-color);
  border-bottom: var(--mixin-50gWQi7Ztf5XKp_border-bottom-width)
    var(--mixin-50gWQi7Ztf5XKp_border-bottom-style)
    var(--mixin-50gWQi7Ztf5XKp_border-bottom-color);
  border-left: var(--mixin-50gWQi7Ztf5XKp_border-left-width)
    var(--mixin-50gWQi7Ztf5XKp_border-left-style)
    var(--mixin-50gWQi7Ztf5XKp_border-left-color);
}

:where(.root_reset) pre:where(.pre),
pre:where(.root_reset.pre),
:where(.root_reset .__wab_expr_html_text) pre,
:where(.root_reset_tags) pre,
pre:where(.root_reset_tags) {
  background: #f8f8f8;
  font-family: var(--mixin-gH4lg7X1xmvpKC_font-family);
  border-radius: var(--mixin-gH4lg7X1xmvpKC_border-top-left-radius)
    var(--mixin-gH4lg7X1xmvpKC_border-top-right-radius)
    var(--mixin-gH4lg7X1xmvpKC_border-bottom-right-radius)
    var(--mixin-gH4lg7X1xmvpKC_border-bottom-left-radius);
  padding: var(--mixin-gH4lg7X1xmvpKC_padding-top)
    var(--mixin-gH4lg7X1xmvpKC_padding-right)
    var(--mixin-gH4lg7X1xmvpKC_padding-bottom)
    var(--mixin-gH4lg7X1xmvpKC_padding-left);
  border-top: var(--mixin-gH4lg7X1xmvpKC_border-top-width)
    var(--mixin-gH4lg7X1xmvpKC_border-top-style)
    var(--mixin-gH4lg7X1xmvpKC_border-top-color);
  border-right: var(--mixin-gH4lg7X1xmvpKC_border-right-width)
    var(--mixin-gH4lg7X1xmvpKC_border-right-style)
    var(--mixin-gH4lg7X1xmvpKC_border-right-color);
  border-bottom: var(--mixin-gH4lg7X1xmvpKC_border-bottom-width)
    var(--mixin-gH4lg7X1xmvpKC_border-bottom-style)
    var(--mixin-gH4lg7X1xmvpKC_border-bottom-color);
  border-left: var(--mixin-gH4lg7X1xmvpKC_border-left-width)
    var(--mixin-gH4lg7X1xmvpKC_border-left-style)
    var(--mixin-gH4lg7X1xmvpKC_border-left-color);
}

:where(.root_reset) ol:where(.ol),
ol:where(.root_reset.ol),
:where(.root_reset .__wab_expr_html_text) ol,
:where(.root_reset_tags) ol,
ol:where(.root_reset_tags) {
  display: var(--mixin-7aHc9CCximODMV_display);
  flex-direction: var(--mixin-7aHc9CCximODMV_flex-direction);
  align-items: var(--mixin-7aHc9CCximODMV_align-items);
  justify-content: var(--mixin-7aHc9CCximODMV_justify-content);
  list-style-position: var(--mixin-7aHc9CCximODMV_list-style-position);
  padding-left: var(--mixin-7aHc9CCximODMV_padding-left);
  position: var(--mixin-7aHc9CCximODMV_position);
  list-style-type: var(--mixin-7aHc9CCximODMV_list-style-type);
  flex-column-gap: var(--mixin-7aHc9CCximODMV_flex-column-gap);
}

:where(.root_reset) ul:where(.ul),
ul:where(.root_reset.ul),
:where(.root_reset .__wab_expr_html_text) ul,
:where(.root_reset_tags) ul,
ul:where(.root_reset_tags) {
  display: var(--mixin-rmFF2_dhZIzKZJ_display);
  flex-direction: var(--mixin-rmFF2_dhZIzKZJ_flex-direction);
  align-items: var(--mixin-rmFF2_dhZIzKZJ_align-items);
  justify-content: var(--mixin-rmFF2_dhZIzKZJ_justify-content);
  list-style-position: var(--mixin-rmFF2_dhZIzKZJ_list-style-position);
  padding-left: var(--mixin-rmFF2_dhZIzKZJ_padding-left);
  position: var(--mixin-rmFF2_dhZIzKZJ_position);
  list-style-type: var(--mixin-rmFF2_dhZIzKZJ_list-style-type);
  flex-column-gap: var(--mixin-rmFF2_dhZIzKZJ_flex-column-gap);
}
