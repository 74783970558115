@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400%3B0%2C700&family=Poppins%3Aital%2Cwght%400%2C400%3B0%2C700&display=swap");

.plasmic_tokens {
  --token-NEon4Y--Z: var(--token-bp_CxDNOQi9);
  --plasmic-token-l-type-text-g-00: var(--token-NEon4Y--Z);
  --token-6-9k9VEZ-: var(--token-T5L5Js4u0Cm);
  --plasmic-token-l-pri-text-g-20: var(--token-6-9k9VEZ-);
  --token-QQdIvJFj1: var(--token-2eUJTxCVL-C);
  --plasmic-token-l-pri-icon-g-30: var(--token-QQdIvJFj1);
  --token-yyaHFSf_c: var(--token-vPebB0QZhXB);
  --plasmic-token-l-sec-text-g-50: var(--token-yyaHFSf_c);
  --token-S2OvgLalS: var(--token-jBhCqxBbh1U);
  --plasmic-token-l-sec-icon-g-60: var(--token-S2OvgLalS);
  --token-eoKrlLGaw: var(--token-jBhCqxBbh1U);
  --plasmic-token-l-dis-text-g-60: var(--token-eoKrlLGaw);
  --token-5Zjzi6myY: var(--token-GpcED8_AcIn);
  --plasmic-token-l-dis-icon-g-80: var(--token-5Zjzi6myY);
  --token-4QM1pZKqI: var(--token-mfYPuA8TIiO);
  --plasmic-token-l-border-divider-g-90: var(--token-4QM1pZKqI);
  --token-3dsm_UqzX: var(--token-mfYPuA8TIiO);
  --plasmic-token-l-sec-button-active-g-90: var(--token-3dsm_UqzX);
  --token-ECewHnPLa: var(--token-mfYPuA8TIiO);
  --plasmic-token-l-sec-button-hover-g-90: var(--token-ECewHnPLa);
  --token-L89ExH6ks: var(--token-J4mdJYsm3rg);
  --plasmic-token-l-sec-button-idle-g-95: var(--token-L89ExH6ks);
  --token-aZB55lECH: var(--token-kfauf58rd4Jw);
  --plasmic-token-l-ter-flat-button-idle-w-100: var(--token-aZB55lECH);
  --token-v6LfSbn-y: var(--token-maWCPk1v_oCK);
  --plasmic-token-l-panel-bg-w-00: var(--token-v6LfSbn-y);
  --token-UHlbKybOG: var(--token-5ERNDT3JbAJ);
  --plasmic-token-d-type-text-g-100: var(--token-UHlbKybOG);
  --token-b6bbqecq5: var(--token-GpcED8_AcIn);
  --plasmic-token-d-pri-text-g-80: var(--token-b6bbqecq5);
  --token-WsLN08IMx: var(--token-TRf5pfsHwLE);
  --plasmic-token-d-pri-icon-g-70: var(--token-WsLN08IMx);
  --token-atlM-KLfh: var(--token-vPebB0QZhXB);
  --plasmic-token-d-sec-text-g-50: var(--token-atlM-KLfh);
  --token-_vo6-mmg5: var(--token-vaTuh5tFL3B);
  --plasmic-token-d-sec-icon-g-40: var(--token-_vo6-mmg5);
  --token-wfRuRexli: var(--token-2eUJTxCVL-C);
  --plasmic-token-d-dis-icon-g-30: var(--token-wfRuRexli);
  --token-dzXZ6pAO5: var(--token-4wGl4vODcpi);
  --plasmic-token-d-border-divider-g-15: var(--token-dzXZ6pAO5);
  --token-IJJ91hnWr: var(--token-4wGl4vODcpi);
  --plasmic-token-d-sec-button-active-g-15: var(--token-IJJ91hnWr);
  --token-yCchoBO7S: var(--token-4wGl4vODcpi);
  --plasmic-token-d-sec-button-hover-g-20: var(--token-yCchoBO7S);
  --token-0XeUy-lw_: var(--token-dph1wZ7NjV9);
  --plasmic-token-d-sec-button-idle-g-10: var(--token-0XeUy-lw_);
  --token-bybe1t4o_: var(--token-Abo-hB2iM8_F);
  --plasmic-token-d-ter-flat-button-idle-b-100: var(--token-bybe1t4o_);
  --token-js0Jm3Eqa: var(--token-T755mmytbnp);
  --plasmic-token-d-panel-bg-g-05: var(--token-js0Jm3Eqa);
  --token-bp_CxDNOQi9: rgb(5, 5, 5);
  --plasmic-token-spectrum-gray-00: var(--token-bp_CxDNOQi9);
  --token-T755mmytbnp: rgb(14, 15, 15);
  --plasmic-token-spectrum-gray-05: var(--token-T755mmytbnp);
  --token-dph1wZ7NjV9: rgb(24, 25, 26);
  --plasmic-token-spectrum-gray-10: var(--token-dph1wZ7NjV9);
  --token-4wGl4vODcpi: rgb(36, 37, 38);
  --plasmic-token-spectrum-gray-15: var(--token-4wGl4vODcpi);
  --token-T5L5Js4u0Cm: rgb(50, 52, 54);
  --plasmic-token-spectrum-gray-20: var(--token-T5L5Js4u0Cm);
  --token-Pjd7dW5DABf: rgb(62, 64, 66);
  --plasmic-token-spectrum-gray-25: var(--token-Pjd7dW5DABf);
  --token-2eUJTxCVL-C: rgb(74, 76, 79);
  --plasmic-token-spectrum-gray-30: var(--token-2eUJTxCVL-C);
  --token-0CZnaBLeXRg: rgb(86, 88, 92);
  --plasmic-token-spectrum-gray-35: var(--token-0CZnaBLeXRg);
  --token-vaTuh5tFL3B: rgb(101, 103, 107);
  --plasmic-token-spectrum-gray-40: var(--token-vaTuh5tFL3B);
  --token-eZ1ct1nGV-R: rgb(110, 113, 117);
  --plasmic-token-spectrum-gray-45: var(--token-eZ1ct1nGV-R);
  --token-vPebB0QZhXB: rgb(122, 125, 130);
  --plasmic-token-spectrum-gray-50: var(--token-vPebB0QZhXB);
  --token-VwtAjYRRGnQ: rgb(138, 141, 145);
  --plasmic-token-spectrum-gray-55: var(--token-VwtAjYRRGnQ);
  --token-jBhCqxBbh1U: rgb(150, 153, 158);
  --plasmic-token-spectrum-gray-60: var(--token-jBhCqxBbh1U);
  --token-ZwCmUzRS8Eq: rgb(164, 167, 171);
  --plasmic-token-spectrum-gray-65: var(--token-ZwCmUzRS8Eq);
  --token-TRf5pfsHwLE: rgb(176, 179, 184);
  --plasmic-token-spectrum-gray-70: var(--token-TRf5pfsHwLE);
  --token-zPC6v7PV_Ck: rgb(188, 192, 196);
  --plasmic-token-spectrum-gray-75: var(--token-zPC6v7PV_Ck);
  --token-GpcED8_AcIn: rgb(201, 204, 209);
  --plasmic-token-spectrum-gray-80: var(--token-GpcED8_AcIn);
  --token-gsiKoYUmNQ1: rgb(215, 218, 222);
  --plasmic-token-spectrum-gray-85: var(--token-gsiKoYUmNQ1);
  --token-mfYPuA8TIiO: rgb(228, 230, 235);
  --plasmic-token-spectrum-gray-90: var(--token-mfYPuA8TIiO);
  --token-J4mdJYsm3rg: rgb(240, 242, 245);
  --plasmic-token-spectrum-gray-95: var(--token-J4mdJYsm3rg);
  --token-5ERNDT3JbAJ: rgb(245, 247, 250);
  --plasmic-token-spectrum-gray-100: var(--token-5ERNDT3JbAJ);
  --token-e9-dFTyQwF5: rgb(0, 11, 27);
  --plasmic-token-spectrum-blue-00: var(--token-e9-dFTyQwF5);
  --token--Jsr4E42hW_: rgb(1, 19, 48);
  --plasmic-token-spectrum-blue-05: var(--token--Jsr4E42hW_);
  --token-5F-5VQv6KIt: rgb(0, 25, 61);
  --plasmic-token-spectrum-blue-10: var(--token-5F-5VQv6KIt);
  --token-vrUg5AsbI50: rgb(5, 33, 77);
  --plasmic-token-spectrum-blue-15: var(--token-vrUg5AsbI50);
  --token-d8dV-mCRCeN: rgb(7, 49, 109);
  --plasmic-token-spectrum-blue-20: var(--token-d8dV-mCRCeN);
  --token-t_D20t2g59x: rgb(8, 62, 137);
  --plasmic-token-spectrum-blue-25: var(--token-t_D20t2g59x);
  --token-V_YeIlhlGJB: rgb(14, 73, 156);
  --plasmic-token-spectrum-blue-30: var(--token-V_YeIlhlGJB);
  --token-PV1M_BXTQ-O: rgb(20, 85, 176);
  --plasmic-token-spectrum-blue-35: var(--token-PV1M_BXTQ-O);
  --token-XwpJY1cPVQ2: rgb(23, 99, 207);
  --plasmic-token-spectrum-blue-40: var(--token-XwpJY1cPVQ2);
  --token-TSmp2czi_eC: rgb(33, 111, 219);
  --plasmic-token-spectrum-blue-45: var(--token-TSmp2czi_eC);
  --token-p-fCTrfjtL4: rgb(24, 119, 242);
  --plasmic-token-spectrum-blue-50: var(--token-p-fCTrfjtL4);
  --token-W_zg1zbEOX5: rgb(45, 136, 255);
  --plasmic-token-spectrum-blue-55: var(--token-W_zg1zbEOX5);
  --token-zvzXM_xtXwX: rgb(69, 153, 255);
  --plasmic-token-spectrum-blue-60: var(--token-zvzXM_xtXwX);
  --token-6ngi-EmWW9L: rgb(95, 170, 255);
  --plasmic-token-spectrum-blue-65: var(--token-6ngi-EmWW9L);
  --token-mJlYp7A417I: rgb(118, 182, 255);
  --plasmic-token-spectrum-blue-70: var(--token-mJlYp7A417I);
  --token-mSoS3SJzfa8: rgb(145, 195, 255);
  --plasmic-token-spectrum-blue-75: var(--token-mSoS3SJzfa8);
  --token-3LV_DaWk8mI: rgb(168, 209, 255);
  --plasmic-token-spectrum-blue-80: var(--token-3LV_DaWk8mI);
  --token-mOtfz5FFI41: rgb(186, 218, 255);
  --plasmic-token-spectrum-blue-85: var(--token-mOtfz5FFI41);
  --token-NCml2bz5oJG: rgb(205, 229, 255);
  --plasmic-token-spectrum-blue-90: var(--token-NCml2bz5oJG);
  --token-GzJX13t1i6P: rgb(231, 243, 255);
  --plasmic-token-spectrum-blue-95: var(--token-GzJX13t1i6P);
  --token-NI7k37YiuK8: rgb(244, 250, 255);
  --plasmic-token-spectrum-blue-100: var(--token-NI7k37YiuK8);
  --token-y9Bh92YbVK5: rgb(0, 16, 33);
  --plasmic-token-spectrum-cyan-00: var(--token-y9Bh92YbVK5);
  --token-DNhSCHY-0ja: rgb(0, 23, 46);
  --plasmic-token-spectrum-cyan-05: var(--token-DNhSCHY-0ja);
  --token-fmSFCLO0qkK: rgb(0, 33, 64);
  --plasmic-token-spectrum-cyan-10: var(--token-fmSFCLO0qkK);
  --token-iRUvHOggag1: rgb(0, 41, 77);
  --plasmic-token-spectrum-cyan-15: var(--token-iRUvHOggag1);
  --token-fuOlnHkDJkX: rgb(0, 53, 94);
  --plasmic-token-spectrum-cyan-20: var(--token-fuOlnHkDJkX);
  --token-sunbrbBa2wC: rgb(0, 72, 124);
  --plasmic-token-spectrum-cyan-25: var(--token-sunbrbBa2wC);
  --token-htNuilQFhI7: rgb(0, 84, 140);
  --plasmic-token-spectrum-cyan-30: var(--token-htNuilQFhI7);
  --token-QOLO_j_mp22: rgb(0, 98, 158);
  --plasmic-token-spectrum-cyan-35: var(--token-QOLO_j_mp22);
  --token-iMI1gRqCCAG: rgb(0, 110, 173);
  --plasmic-token-spectrum-cyan-40: var(--token-iMI1gRqCCAG);
  --token-2AaxpihagxH: rgb(0, 129, 193);
  --plasmic-token-spectrum-cyan-45: var(--token-2AaxpihagxH);
  --token-yKXmGnP3Nte: rgb(0, 139, 208);
  --plasmic-token-spectrum-cyan-50: var(--token-yKXmGnP3Nte);
  --token-7t3bUsmR2G1: rgb(0, 153, 230);
  --plasmic-token-spectrum-cyan-55: var(--token-7t3bUsmR2G1);
  --token-eI5XVwQOs8t: rgb(4, 164, 244);
  --plasmic-token-spectrum-cyan-60: var(--token-eI5XVwQOs8t);
  --token-8klLkRaFR96: rgb(38, 183, 255);
  --plasmic-token-spectrum-cyan-65: var(--token-8klLkRaFR96);
  --token-P2GhlSti3mm: rgb(79, 196, 251);
  --plasmic-token-spectrum-cyan-70: var(--token-P2GhlSti3mm);
  --token-JPQzRqZRPI6: rgb(110, 209, 255);
  --plasmic-token-spectrum-cyan-75: var(--token-JPQzRqZRPI6);
  --token-goIDDUrSACn: rgb(134, 218, 255);
  --plasmic-token-spectrum-cyan-80: var(--token-goIDDUrSACn);
  --token-yoeM7EjC1fw: rgb(163, 230, 255);
  --plasmic-token-spectrum-cyan-85: var(--token-yoeM7EjC1fw);
  --token-9hJgiHiPfM_: rgb(198, 239, 255);
  --plasmic-token-spectrum-cyan-90: var(--token-9hJgiHiPfM_);
  --token-ro4OjMDnNRi: rgb(224, 247, 255);
  --plasmic-token-spectrum-cyan-95: var(--token-ro4OjMDnNRi);
  --token-9itojFQKF7n: rgb(245, 253, 255);
  --plasmic-token-spectrum-cyan-100: var(--token-9itojFQKF7n);
  --token-_5cyN1DbqZ0: rgb(0, 20, 18);
  --plasmic-token-spectrum-teal-00: var(--token-_5cyN1DbqZ0);
  --token-22kIxDk0aIa: rgb(0, 27, 24);
  --plasmic-token-spectrum-teal-05: var(--token-22kIxDk0aIa);
  --token-UhZswNMZ-Jv: rgb(0, 39, 34);
  --plasmic-token-spectrum-teal-10: var(--token-UhZswNMZ-Jv);
  --token-Ma724vkmYNu: rgb(3, 53, 46);
  --plasmic-token-spectrum-teal-15: var(--token-Ma724vkmYNu);
  --token-GilDpuJe4KJ: rgb(6, 67, 58);
  --plasmic-token-spectrum-teal-20: var(--token-GilDpuJe4KJ);
  --token-WgoOSKm7hjj: rgb(8, 81, 71);
  --plasmic-token-spectrum-teal-25: var(--token-WgoOSKm7hjj);
  --token-kXyCtlx2_je: rgb(8, 94, 82);
  --plasmic-token-spectrum-teal-30: var(--token-kXyCtlx2_je);
  --token-BQ8RkOCiVan: rgb(0, 110, 95);
  --plasmic-token-spectrum-teal-35: var(--token-BQ8RkOCiVan);
  --token-3qpNm-Exccn: rgb(2, 127, 110);
  --plasmic-token-spectrum-teal-40: var(--token-3qpNm-Exccn);
  --token-PWKRehLxC2d: rgb(3, 142, 123);
  --plasmic-token-spectrum-teal-45: var(--token-PWKRehLxC2d);
  --token-WArk1F2mzlv: rgb(9, 158, 137);
  --plasmic-token-spectrum-teal-50: var(--token-WArk1F2mzlv);
  --token-BfHI_MbEDkJ: rgb(14, 172, 150);
  --plasmic-token-spectrum-teal-55: var(--token-BfHI_MbEDkJ);
  --token-9cWUWJecWJ5: rgb(42, 187, 167);
  --plasmic-token-spectrum-teal-60: var(--token-9cWUWJecWJ5);
  --token-gT8g27-U2FL: rgb(48, 200, 180);
  --plasmic-token-spectrum-teal-65: var(--token-gT8g27-U2FL);
  --token-F3MhjcPmMKu: rgb(67, 209, 190);
  --plasmic-token-spectrum-teal-70: var(--token-F3MhjcPmMKu);
  --token-KCUYT689Q5o: rgb(121, 218, 205);
  --plasmic-token-spectrum-teal-75: var(--token-KCUYT689Q5o);
  --token-7hFN9j8gzk-: rgb(149, 228, 217);
  --plasmic-token-spectrum-teal-80: var(--token-7hFN9j8gzk-);
  --token-Jlp18DIKL87: rgb(181, 241, 233);
  --plasmic-token-spectrum-teal-85: var(--token-Jlp18DIKL87);
  --token-m6gRzgjRheQ: rgb(208, 247, 242);
  --plasmic-token-spectrum-teal-90: var(--token-m6gRzgjRheQ);
  --token-wUUFmxQnNSa: rgb(227, 249, 246);
  --plasmic-token-spectrum-teal-95: var(--token-wUUFmxQnNSa);
  --token-nmKvx8-EPRf: rgb(240, 252, 251);
  --plasmic-token-spectrum-teal-100: var(--token-nmKvx8-EPRf);
  --token--mScoqg4oX4: rgb(4, 23, 8);
  --plasmic-token-spectrum-green-00: var(--token--mScoqg4oX4);
  --token-8R0QVMrjdJ2: rgb(8, 32, 13);
  --plasmic-token-spectrum-green-05: var(--token-8R0QVMrjdJ2);
  --token-ah8KS31e7NV: rgb(12, 40, 18);
  --plasmic-token-spectrum-green-10: var(--token-ah8KS31e7NV);
  --token-c3WSMhPICgq: rgb(16, 55, 25);
  --plasmic-token-spectrum-green-15: var(--token-c3WSMhPICgq);
  --token-6nuMN7bCKet: rgb(24, 71, 35);
  --plasmic-token-spectrum-green-20: var(--token-6nuMN7bCKet);
  --token-jg-C8t046F0: rgb(25, 85, 39);
  --plasmic-token-spectrum-green-25: var(--token-jg-C8t046F0);
  --token-VDlEEijoqXR: rgb(29, 99, 46);
  --plasmic-token-spectrum-green-30: var(--token-VDlEEijoqXR);
  --token-Qj37rl18KWM: rgb(37, 117, 57);
  --plasmic-token-spectrum-green-35: var(--token-Qj37rl18KWM);
  --token-QYnuiG8aPE3: rgb(36, 133, 60);
  --plasmic-token-spectrum-green-40: var(--token-QYnuiG8aPE3);
  --token-yErWpZvXqXt: rgb(42, 145, 66);
  --plasmic-token-spectrum-green-45: var(--token-yErWpZvXqXt);
  --token-h7X-5Dh5s6d: rgb(49, 162, 76);
  --plasmic-token-spectrum-green-50: var(--token-h7X-5Dh5s6d);
  --token-PUfHqE8XdOd: rgb(50, 171, 79);
  --plasmic-token-spectrum-green-55: var(--token-PUfHqE8XdOd);
  --token-NEdiZacoLfV: rgb(69, 189, 98);
  --plasmic-token-spectrum-green-60: var(--token-NEdiZacoLfV);
  --token-yj-VHfapQL1: rgb(81, 206, 112);
  --plasmic-token-spectrum-green-65: var(--token-yj-VHfapQL1);
  --token-vaiH0GEC8Ah: rgb(104, 218, 132);
  --plasmic-token-spectrum-green-70: var(--token-vaiH0GEC8Ah);
  --token-yPprYoaZEvI: rgb(136, 227, 159);
  --plasmic-token-spectrum-green-75: var(--token-yPprYoaZEvI);
  --token-DMhxhco7ehd: rgb(163, 230, 181);
  --plasmic-token-spectrum-green-80: var(--token-DMhxhco7ehd);
  --token-3K75WzHjhWk: rgb(188, 243, 203);
  --plasmic-token-spectrum-green-85: var(--token-3K75WzHjhWk);
  --token-FTw-ehjo-QU: rgb(203, 249, 215);
  --plasmic-token-spectrum-green-90: var(--token-FTw-ehjo-QU);
  --token-9bovyZikTcr: rgb(221, 251, 229);
  --plasmic-token-spectrum-green-95: var(--token-9bovyZikTcr);
  --token-7Yxw1_ohuxh: rgb(239, 255, 243);
  --plasmic-token-spectrum-green-100: var(--token-7Yxw1_ohuxh);
  --token-BwRB5smSSms: rgb(42, 16, 0);
  --plasmic-token-spectrum-yellow-00: var(--token-BwRB5smSSms);
  --token-JDaRvhlCsXx: rgb(56, 22, 1);
  --plasmic-token-spectrum-yellow-05: var(--token-JDaRvhlCsXx);
  --token-WhbkJg3D4Qd: rgb(74, 31, 0);
  --plasmic-token-spectrum-yellow-10: var(--token-WhbkJg3D4Qd);
  --token-3C2rLwsRbdp: rgb(95, 40, 0);
  --plasmic-token-spectrum-yellow-15: var(--token-3C2rLwsRbdp);
  --token-xtXdFFmuhp1F: rgb(120, 51, 1);
  --plasmic-token-spectrum-yellow-20: var(--token-xtXdFFmuhp1F);
  --token-u2kedaudR8bP: rgb(156, 67, 0);
  --plasmic-token-spectrum-yellow-25: var(--token-u2kedaudR8bP);
  --token-s-R9IRq5T4nK: rgb(181, 84, 0);
  --plasmic-token-spectrum-yellow-30: var(--token-s-R9IRq5T4nK);
  --token-lWt9IGYih6QF: rgb(197, 97, 9);
  --plasmic-token-spectrum-yellow-35: var(--token-lWt9IGYih6QF);
  --token-30EvLWB_wwaf: rgb(209, 111, 20);
  --plasmic-token-spectrum-yellow-40: var(--token-30EvLWB_wwaf);
  --token-GcwBAJtdG3Xn: rgb(218, 134, 0);
  --plasmic-token-spectrum-yellow-45: var(--token-GcwBAJtdG3Xn);
  --token-R8KoXnAKuOzE: rgb(230, 151, 16);
  --plasmic-token-spectrum-yellow-50: var(--token-R8KoXnAKuOzE);
  --token-7mnmJczFvgQl: rgb(241, 168, 23);
  --plasmic-token-spectrum-yellow-55: var(--token-7mnmJczFvgQl);
  --token-Wcxphanqg_td: rgb(247, 185, 40);
  --plasmic-token-spectrum-yellow-60: var(--token-Wcxphanqg_td);
  --token-dDlXGr70ZWMM: rgb(252, 193, 43);
  --plasmic-token-spectrum-yellow-65: var(--token-dDlXGr70ZWMM);
  --token-Y4S3kOT-8OhA: rgb(255, 206, 46);
  --plasmic-token-spectrum-yellow-70: var(--token-Y4S3kOT-8OhA);
  --token-StPzu-zvNckv: rgb(255, 215, 69);
  --plasmic-token-spectrum-yellow-75: var(--token-StPzu-zvNckv);
  --token-bEsNbrm_BiTk: rgb(255, 223, 97);
  --plasmic-token-spectrum-yellow-80: var(--token-bEsNbrm_BiTk);
  --token-9n9SgRMXP338: rgb(255, 236, 129);
  --plasmic-token-spectrum-yellow-85: var(--token-9n9SgRMXP338);
  --token-lanLFf_SczBI: rgb(255, 242, 166);
  --plasmic-token-spectrum-yellow-90: var(--token-lanLFf_SczBI);
  --token-omXnNA4qxtAT: rgb(255, 249, 209);
  --plasmic-token-spectrum-yellow-95: var(--token-omXnNA4qxtAT);
  --token-GQwoduWrvOJY: rgb(255, 253, 242);
  --plasmic-token-spectrum-yellow-100: var(--token-GQwoduWrvOJY);
  --token-jUUszkQqhF05: rgb(41, 17, 0);
  --plasmic-token-spectrum-orange-00: var(--token-jUUszkQqhF05);
  --token-K9MoKRQxn5q3: rgb(56, 21, 1);
  --plasmic-token-spectrum-orange-05: var(--token-K9MoKRQxn5q3);
  --token-FcPp052ELCXS: rgb(74, 27, 0);
  --plasmic-token-spectrum-orange-10: var(--token-FcPp052ELCXS);
  --token-cwzRZhlU7HQz: rgb(122, 33, 3);
  --plasmic-token-spectrum-orange-15: var(--token-cwzRZhlU7HQz);
  --token-4i48xdmz3-Hu: rgb(152, 45, 0);
  --plasmic-token-spectrum-orange-20: var(--token-4i48xdmz3-Hu);
  --token-6G14aJS7Wpi8: rgb(171, 58, 2);
  --plasmic-token-spectrum-orange-25: var(--token-6G14aJS7Wpi8);
  --token-2jyeDxSNo1FQ: rgb(194, 68, 0);
  --plasmic-token-spectrum-orange-30: var(--token-2jyeDxSNo1FQ);
  --token-ivwHhC8R8DVT: rgb(212, 78, 6);
  --plasmic-token-spectrum-orange-35: var(--token-ivwHhC8R8DVT);
  --token-uoVRyvJZCiCV: rgb(235, 99, 14);
  --plasmic-token-spectrum-orange-40: var(--token-uoVRyvJZCiCV);
  --token--MnlIGTySoD4: rgb(240, 112, 26);
  --plasmic-token-spectrum-orange-45: var(--token--MnlIGTySoD4);
  --token-FPKbzT8uY-pb: rgb(251, 124, 34);
  --plasmic-token-spectrum-orange-50: var(--token-FPKbzT8uY-pb);
  --token-_tD3YlpmSRSw: rgb(255, 137, 47);
  --plasmic-token-spectrum-orange-55: var(--token-_tD3YlpmSRSw);
  --token-oy-e2YroYVbl: rgb(255, 152, 49);
  --plasmic-token-spectrum-orange-60: var(--token-oy-e2YroYVbl);
  --token-it-aGjEUQ3Zj: rgb(255, 165, 69);
  --plasmic-token-spectrum-orange-65: var(--token-it-aGjEUQ3Zj);
  --token--NdqT2OstqhO: rgb(255, 172, 89);
  --plasmic-token-spectrum-orange-70: var(--token--NdqT2OstqhO);
  --token-TVaxsGEPLD5y: rgb(255, 185, 115);
  --plasmic-token-spectrum-orange-75: var(--token-TVaxsGEPLD5y);
  --token-EQV-vZlLXXKG: rgb(255, 194, 133);
  --plasmic-token-spectrum-orange-80: var(--token-EQV-vZlLXXKG);
  --token-YIpWG3CY3qX0: rgb(255, 204, 153);
  --plasmic-token-spectrum-orange-85: var(--token-YIpWG3CY3qX0);
  --token-hqwXvSbQU7tA: rgb(255, 219, 183);
  --plasmic-token-spectrum-orange-90: var(--token-hqwXvSbQU7tA);
  --token-3YKfcu7eywe6: rgb(255, 233, 212);
  --plasmic-token-spectrum-orange-95: var(--token-3YKfcu7eywe6);
  --token-8sS9cMRZcuTo: rgb(255, 246, 237);
  --plasmic-token-spectrum-orange-100: var(--token-8sS9cMRZcuTo);
  --token-L-VuRjpNvvIU: rgb(50, 7, 1);
  --plasmic-token-spectrum-coral-00: var(--token-L-VuRjpNvvIU);
  --token-2pr06AkQWJ9S: rgb(74, 9, 0);
  --plasmic-token-spectrum-coral-05: var(--token-2pr06AkQWJ9S);
  --token-oWct2cJ9s6Jy: rgb(89, 11, 1);
  --plasmic-token-spectrum-coral-10: var(--token-oWct2cJ9s6Jy);
  --token-0wNzpOkfaeR3: rgb(122, 22, 9);
  --plasmic-token-spectrum-coral-15: var(--token-0wNzpOkfaeR3);
  --token-hAbPtm07Y4Nj: rgb(152, 31, 15);
  --plasmic-token-spectrum-coral-20: var(--token-hAbPtm07Y4Nj);
  --token-OnPZRslUy0Tw: rgb(170, 35, 18);
  --plasmic-token-spectrum-coral-25: var(--token-OnPZRslUy0Tw);
  --token-ULesvY2thYQS: rgb(191, 42, 24);
  --plasmic-token-spectrum-coral-30: var(--token-ULesvY2thYQS);
  --token-jz90KSwvql8Y: rgb(212, 49, 28);
  --plasmic-token-spectrum-coral-35: var(--token-jz90KSwvql8Y);
  --token-WTGg9WgD8OW_: rgb(224, 62, 40);
  --plasmic-token-spectrum-coral-40: var(--token-WTGg9WgD8OW_);
  --token-xy6bgT6shZpj: rgb(237, 74, 52);
  --plasmic-token-spectrum-coral-45: var(--token-xy6bgT6shZpj);
  --token-pVFLnMThXoWY: rgb(245, 83, 61);
  --plasmic-token-spectrum-coral-50: var(--token-pVFLnMThXoWY);
  --token-72YZYi8iC-aQ: rgb(255, 104, 84);
  --plasmic-token-spectrum-coral-55: var(--token-72YZYi8iC-aQ);
  --token-a_yO91dRqLjy: rgb(255, 122, 105);
  --plasmic-token-spectrum-coral-60: var(--token-a_yO91dRqLjy);
  --token-isWvn3V3B47F: rgb(255, 144, 130);
  --plasmic-token-spectrum-coral-65: var(--token-isWvn3V3B47F);
  --token-5Ni--L6qzPZH: rgb(255, 158, 145);
  --plasmic-token-spectrum-coral-70: var(--token-5Ni--L6qzPZH);
  --token-Sv6gKBU6YRf1: rgb(255, 172, 161);
  --plasmic-token-spectrum-coral-75: var(--token-Sv6gKBU6YRf1);
  --token-kRpu_Gmxxq5A: rgb(255, 186, 176);
  --plasmic-token-spectrum-coral-80: var(--token-kRpu_Gmxxq5A);
  --token-O-_kuWXKoTZI: rgb(255, 202, 194);
  --plasmic-token-spectrum-coral-85: var(--token-O-_kuWXKoTZI);
  --token-Dv1Ei_CRKdk5: rgb(255, 214, 209);
  --plasmic-token-spectrum-coral-90: var(--token-Dv1Ei_CRKdk5);
  --token-Fjq_7SBXWb--: rgb(255, 230, 227);
  --plasmic-token-spectrum-coral-95: var(--token-Fjq_7SBXWb--);
  --token-1SW1z3KgQNqu: rgb(255, 244, 242);
  --plasmic-token-spectrum-coral-100: var(--token-1SW1z3KgQNqu);
  --token-nsZPQWiSh3qB: rgb(61, 0, 10);
  --plasmic-token-spectrum-red-00: var(--token-nsZPQWiSh3qB);
  --token-aYWjCONS5p8z: rgb(80, 1, 14);
  --plasmic-token-spectrum-red-05: var(--token-aYWjCONS5p8z);
  --token-cqLopCkFNMvz: rgb(99, 1, 17);
  --plasmic-token-spectrum-red-10: var(--token-cqLopCkFNMvz);
  --token-O9wbgnulNstm: rgb(130, 3, 24);
  --plasmic-token-spectrum-red-15: var(--token-O9wbgnulNstm);
  --token-RJCO_mNprqXJ: rgb(165, 3, 38);
  --plasmic-token-spectrum-red-20: var(--token-RJCO_mNprqXJ);
  --token-_VI3go6JORoJ: rgb(189, 13, 42);
  --plasmic-token-spectrum-red-25: var(--token-_VI3go6JORoJ);
  --token-V-9pSVr73ZLs: rgb(211, 30, 60);
  --plasmic-token-spectrum-red-30: var(--token-V-9pSVr73ZLs);
  --token-5WYCQ42X6zbk: rgb(228, 30, 63);
  --plasmic-token-spectrum-red-35: var(--token-5WYCQ42X6zbk);
  --token-VceSq-t-hA6m: rgb(240, 40, 73);
  --plasmic-token-spectrum-red-40: var(--token-VceSq-t-hA6m);
  --token-PB6RFS1UZi5y: rgb(243, 66, 95);
  --plasmic-token-spectrum-red-45: var(--token-PB6RFS1UZi5y);
  --token-NdhJ0-Yhk2xd: rgb(252, 87, 118);
  --plasmic-token-spectrum-red-50: var(--token-NdhJ0-Yhk2xd);
  --token-L6bB4uyVQ9Dz: rgb(252, 109, 135);
  --plasmic-token-spectrum-red-55: var(--token-L6bB4uyVQ9Dz);
  --token-JHS7XJmZLJ20: rgb(255, 133, 159);
  --plasmic-token-spectrum-red-60: var(--token-JHS7XJmZLJ20);
  --token-N_tuyV5y2J1b: rgb(254, 147, 171);
  --plasmic-token-spectrum-red-65: var(--token-N_tuyV5y2J1b);
  --token-ua_3NFCbOgIq: rgb(255, 158, 179);
  --plasmic-token-spectrum-red-70: var(--token-ua_3NFCbOgIq);
  --token-jfKUenkvbEm6: rgb(255, 173, 198);
  --plasmic-token-spectrum-red-75: var(--token-jfKUenkvbEm6);
  --token-za12eq5c0P_6: rgb(255, 189, 211);
  --plasmic-token-spectrum-red-80: var(--token-za12eq5c0P_6);
  --token-WAD60TteQSoY: rgb(255, 199, 219);
  --plasmic-token-spectrum-red-85: var(--token-WAD60TteQSoY);
  --token-JOGM3OXwEPBG: rgb(255, 217, 231);
  --plasmic-token-spectrum-red-90: var(--token-JOGM3OXwEPBG);
  --token-PUZuTwdwl0b-: rgb(255, 230, 239);
  --plasmic-token-spectrum-red-95: var(--token-PUZuTwdwl0b-);
  --token-frV4y1YFeOzn: rgb(255, 242, 247);
  --plasmic-token-spectrum-red-100: var(--token-frV4y1YFeOzn);
  --token-FJZOBD9bZVs3: rgb(64, 1, 41);
  --plasmic-token-spectrum-pink-00: var(--token-FJZOBD9bZVs3);
  --token-ES0LDgQozg_D: rgb(84, 0, 49);
  --plasmic-token-spectrum-pink-05: var(--token-ES0LDgQozg_D);
  --token-uJeO4pOKUCec: rgb(104, 1, 61);
  --plasmic-token-spectrum-pink-10: var(--token-uJeO4pOKUCec);
  --token-JXK77FpHbDR-: rgb(133, 5, 80);
  --plasmic-token-spectrum-pink-15: var(--token-JXK77FpHbDR-);
  --token-oO-B26XCKyEw: rgb(163, 10, 99);
  --plasmic-token-spectrum-pink-20: var(--token-oO-B26XCKyEw);
  --token-MPmBeHb0kFK4: rgb(191, 15, 118);
  --plasmic-token-spectrum-pink-25: var(--token-MPmBeHb0kFK4);
  --token-xpqb2gIetIwX: rgb(219, 26, 139);
  --plasmic-token-spectrum-pink-30: var(--token-xpqb2gIetIwX);
  --token-yzvkTl4QFt3h: rgb(228, 44, 151);
  --plasmic-token-spectrum-pink-35: var(--token-yzvkTl4QFt3h);
  --token-HLDvDcrQsnJB: rgb(237, 65, 165);
  --plasmic-token-spectrum-pink-40: var(--token-HLDvDcrQsnJB);
  --token-TWY2CtCUhDWt: rgb(248, 87, 180);
  --plasmic-token-spectrum-pink-45: var(--token-TWY2CtCUhDWt);
  --token-JwjM4lx8p2A8: rgb(255, 102, 191);
  --plasmic-token-spectrum-pink-50: var(--token-JwjM4lx8p2A8);
  --token-FaES7QPeehXH: rgb(252, 123, 198);
  --plasmic-token-spectrum-pink-55: var(--token-FaES7QPeehXH);
  --token-x_gXbfeuHvQs: rgb(248, 137, 211);
  --plasmic-token-spectrum-pink-60: var(--token-x_gXbfeuHvQs);
  --token-MToatPLpyKS9: rgb(255, 148, 216);
  --plasmic-token-spectrum-pink-65: var(--token-MToatPLpyKS9);
  --token-UgvHX1ZCOGYw: rgb(255, 158, 223);
  --plasmic-token-spectrum-pink-70: var(--token-UgvHX1ZCOGYw);
  --token-rDwY_aRrN_aU: rgb(255, 173, 228);
  --plasmic-token-spectrum-pink-75: var(--token-rDwY_aRrN_aU);
  --token-9nJ-Cq0Rz3Sd: rgb(255, 189, 233);
  --plasmic-token-spectrum-pink-80: var(--token-9nJ-Cq0Rz3Sd);
  --token-77-YBPYPRBHe: rgb(255, 199, 236);
  --plasmic-token-spectrum-pink-85: var(--token-77-YBPYPRBHe);
  --token-cLXX7vJtl2nB: rgb(255, 217, 242);
  --plasmic-token-spectrum-pink-90: var(--token-cLXX7vJtl2nB);
  --token-61ppnxUeEN7r: rgb(255, 230, 247);
  --plasmic-token-spectrum-pink-95: var(--token-61ppnxUeEN7r);
  --token-82Gq7BKcFt6f: rgb(255, 242, 251);
  --plasmic-token-spectrum-pink-100: var(--token-82Gq7BKcFt6f);
  --token-mgbhBhpJSBtR: rgb(24, 3, 65);
  --plasmic-token-spectrum-purple-00: var(--token-mgbhBhpJSBtR);
  --token-dpxDUAYFnNEO: rgb(34, 8, 85);
  --plasmic-token-spectrum-purple-05: var(--token-dpxDUAYFnNEO);
  --token-oilRLrio2JQj: rgb(49, 15, 117);
  --plasmic-token-spectrum-purple-10: var(--token-oilRLrio2JQj);
  --token-UzFClOJdB0Ak: rgb(63, 22, 145);
  --plasmic-token-spectrum-purple-15: var(--token-UzFClOJdB0Ak);
  --token-bpOmjvFsd4w7: rgb(75, 26, 173);
  --plasmic-token-spectrum-purple-20: var(--token-bpOmjvFsd4w7);
  --token-rIih8wZ2nsYL: rgb(90, 36, 199);
  --plasmic-token-spectrum-purple-25: var(--token-rIih8wZ2nsYL);
  --token-o8E74APkKCqn: rgb(98, 42, 210);
  --plasmic-token-spectrum-purple-30: var(--token-o8E74APkKCqn);
  --token-eGNikobF_fZh: rgb(106, 48, 221);
  --plasmic-token-spectrum-purple-35: var(--token-eGNikobF_fZh);
  --token-j66tZMkmScX9: rgb(118, 62, 230);
  --plasmic-token-spectrum-purple-40: var(--token-j66tZMkmScX9);
  --token-TofnYUvxKNBJ: rgb(129, 77, 231);
  --plasmic-token-spectrum-purple-45: var(--token-TofnYUvxKNBJ);
  --token-oFzCaguTc-Gg: rgb(147, 96, 247);
  --plasmic-token-spectrum-purple-50: var(--token-oFzCaguTc-Gg);
  --token-LE-Jk52ee3E4: rgb(157, 109, 252);
  --plasmic-token-spectrum-purple-55: var(--token-LE-Jk52ee3E4);
  --token-lH2nrqv_zgU3: rgb(168, 124, 255);
  --plasmic-token-spectrum-purple-60: var(--token-lH2nrqv_zgU3);
  --token-VraALBsOx5Zj: rgb(178, 144, 245);
  --plasmic-token-spectrum-purple-65: var(--token-VraALBsOx5Zj);
  --token-W6uwm_0vjacp: rgb(190, 160, 250);
  --plasmic-token-spectrum-purple-70: var(--token-W6uwm_0vjacp);
  --token-4psULaf-9HSo: rgb(200, 178, 247);
  --plasmic-token-spectrum-purple-75: var(--token-4psULaf-9HSo);
  --token-N4TnpPSWsLL_: rgb(213, 194, 255);
  --plasmic-token-spectrum-purple-80: var(--token-N4TnpPSWsLL_);
  --token-rqOpOzbFMxRT: rgb(221, 204, 255);
  --plasmic-token-spectrum-purple-85: var(--token-rqOpOzbFMxRT);
  --token-nBDS_ep4caTe: rgb(229, 217, 255);
  --plasmic-token-spectrum-purple-90: var(--token-nBDS_ep4caTe);
  --token-BubW0ZvMaYye: rgb(239, 232, 255);
  --plasmic-token-spectrum-purple-95: var(--token-BubW0ZvMaYye);
  --token-zDoVXTx27udG: rgb(248, 245, 255);
  --plasmic-token-spectrum-purple-100: var(--token-zDoVXTx27udG);
  --token-Hr1egt3PkNIr: rgb(0, 0, 0);
  --plasmic-token-alpha-black-00: var(--token-Hr1egt3PkNIr);
  --token-IMyM_1NPNPjH: rgba(0, 0, 0, 0.95);
  --plasmic-token-alpha-black-05: var(--token-IMyM_1NPNPjH);
  --token-ChmjNJ38FAuV: rgba(0, 0, 0, 0.9);
  --plasmic-token-alpha-black-10: var(--token-ChmjNJ38FAuV);
  --token-FljSoDZPCz_O: rgba(0, 0, 0, 0.85);
  --plasmic-token-alpha-black-15: var(--token-FljSoDZPCz_O);
  --token-7SxYpZBVArlL: rgba(0, 0, 0, 0.8);
  --plasmic-token-alpha-black-20: var(--token-7SxYpZBVArlL);
  --token-HltjYGlOtO2O: rgba(0, 0, 0, 0.75);
  --plasmic-token-alpha-black-25: var(--token-HltjYGlOtO2O);
  --token-Xb8TvaImqxGW: rgba(0, 0, 0, 0.7);
  --plasmic-token-alpha-black-30: var(--token-Xb8TvaImqxGW);
  --token-eJcIAtpRoR9D: rgba(0, 0, 0, 0.65);
  --plasmic-token-alpha-black-35: var(--token-eJcIAtpRoR9D);
  --token-rovgTji9SIx8: rgba(0, 0, 0, 0.6);
  --plasmic-token-alpha-black-40: var(--token-rovgTji9SIx8);
  --token-vaQGjo_c8nrH: rgba(0, 0, 0, 0.55);
  --plasmic-token-alpha-black-45: var(--token-vaQGjo_c8nrH);
  --token-nIMUVZsFLWJm: rgba(0, 0, 0, 0.5);
  --plasmic-token-alpha-black-50: var(--token-nIMUVZsFLWJm);
  --token-E1V3Dd7vKSZg: rgba(0, 0, 0, 0.45);
  --plasmic-token-alpha-black-55: var(--token-E1V3Dd7vKSZg);
  --token-Lppy2DB30yK3: rgba(0, 0, 0, 0.4);
  --plasmic-token-alpha-black-60: var(--token-Lppy2DB30yK3);
  --token-hy2i1C6RZlH4: rgba(0, 0, 0, 0.35);
  --plasmic-token-alpha-black-65: var(--token-hy2i1C6RZlH4);
  --token-5zxreUaEBWxr: rgba(0, 0, 0, 0.3);
  --plasmic-token-alpha-black-70: var(--token-5zxreUaEBWxr);
  --token-6tLQ6ukh8kJw: rgba(0, 0, 0, 0.25);
  --plasmic-token-alpha-black-75: var(--token-6tLQ6ukh8kJw);
  --token-hgub0LriYmoI: rgba(0, 0, 0, 0.2);
  --plasmic-token-alpha-black-80: var(--token-hgub0LriYmoI);
  --token-OOswLOta7uV3: rgba(0, 0, 0, 0.15);
  --plasmic-token-alpha-black-85: var(--token-OOswLOta7uV3);
  --token-9AWN-rYzsFoW: rgba(0, 0, 0, 0.1);
  --plasmic-token-alpha-black-90: var(--token-9AWN-rYzsFoW);
  --token-bwNWpmaBjHw_: rgba(0, 0, 0, 0.05);
  --plasmic-token-alpha-black-95: var(--token-bwNWpmaBjHw_);
  --token-Abo-hB2iM8_F: rgba(0, 0, 0, 0);
  --plasmic-token-alpha-black-100: var(--token-Abo-hB2iM8_F);
  --token-maWCPk1v_oCK: rgb(255, 255, 255);
  --plasmic-token-alpha-white-00: var(--token-maWCPk1v_oCK);
  --token-wIwgUk-cAJyL: rgba(255, 255, 255, 0.95);
  --plasmic-token-alpha-white-05: var(--token-wIwgUk-cAJyL);
  --token-LJZC6mTRXO7-: rgba(255, 255, 255, 0.9);
  --plasmic-token-alpha-white-10: var(--token-LJZC6mTRXO7-);
  --token-hv4MJvrl7n9u: rgba(255, 255, 255, 0.85);
  --plasmic-token-alpha-white-15: var(--token-hv4MJvrl7n9u);
  --token-YZRlyvrgcoVq: rgba(255, 255, 255, 0.8);
  --plasmic-token-alpha-white-20: var(--token-YZRlyvrgcoVq);
  --token-DTXp8dXhEezW: rgba(255, 255, 255, 0.75);
  --plasmic-token-alpha-white-25: var(--token-DTXp8dXhEezW);
  --token-kstqMq6waVmu: rgba(255, 255, 255, 0.7);
  --plasmic-token-alpha-white-30: var(--token-kstqMq6waVmu);
  --token-tZ7Lot32IHBH: rgba(255, 255, 255, 0.65);
  --plasmic-token-alpha-white-35: var(--token-tZ7Lot32IHBH);
  --token-Sf4IDXdWh0fQ: rgba(255, 255, 255, 0.6);
  --plasmic-token-alpha-white-40: var(--token-Sf4IDXdWh0fQ);
  --token-vv9sjWHNyK3U: rgba(255, 255, 255, 0.55);
  --plasmic-token-alpha-white-45: var(--token-vv9sjWHNyK3U);
  --token-dPp0q968UKXc: rgba(255, 255, 255, 0.5);
  --plasmic-token-alpha-white-50: var(--token-dPp0q968UKXc);
  --token-UTJZ8fnU3F3W: rgba(255, 255, 255, 0.45);
  --plasmic-token-alpha-white-55: var(--token-UTJZ8fnU3F3W);
  --token-1pyew84W1_tG: rgba(255, 255, 255, 0.4);
  --plasmic-token-alpha-white-60: var(--token-1pyew84W1_tG);
  --token-Y8ENqyF2vsIq: rgba(255, 255, 255, 0.35);
  --plasmic-token-alpha-white-65: var(--token-Y8ENqyF2vsIq);
  --token-kmvCo0Xo0JsF: rgba(255, 255, 255, 0.3);
  --plasmic-token-alpha-white-70: var(--token-kmvCo0Xo0JsF);
  --token-tYLoqIwS21vN: rgba(255, 255, 255, 0.25);
  --plasmic-token-alpha-white-75: var(--token-tYLoqIwS21vN);
  --token-t5csGDeNIr0e: rgba(255, 255, 255, 0.2);
  --plasmic-token-alpha-white-80: var(--token-t5csGDeNIr0e);
  --token-lW7TQoj6E9eA: rgba(255, 255, 255, 0.15);
  --plasmic-token-alpha-white-85: var(--token-lW7TQoj6E9eA);
  --token-3q99LuahuYzs: rgba(255, 255, 255, 0.1);
  --plasmic-token-alpha-white-90: var(--token-3q99LuahuYzs);
  --token-biRhQfkdlvly: rgba(255, 255, 255, 0.05);
  --plasmic-token-alpha-white-95: var(--token-biRhQfkdlvly);
  --token-kfauf58rd4Jw: rgba(255, 255, 255, 0);
  --plasmic-token-alpha-white-100: var(--token-kfauf58rd4Jw);
}

.plasmic_tokens {
  --plsmc-standard-width: 800px;
  --plsmc-wide-width: 1280px;
  --plsmc-viewport-gap: 16px;
  --plsmc-wide-chunk: calc(
    ((var(--plsmc-wide-width) - var(--plsmc-standard-width)) / 2) -
      var(--plsmc-viewport-gap)
  );
}

.plasmic_default_styles {
  --mixin-iE6NFqc7Cpb_font-family: "Inter", sans-serif;
  --mixin-iE6NFqc7Cpb_color: var(--token-6-9k9VEZ-);
  --mixin-iE6NFqc7Cpb_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
:where(.root_reset) {
  font-family: var(--mixin-iE6NFqc7Cpb_font-family);
  color: var(--mixin-iE6NFqc7Cpb_color);
  white-space: var(--mixin-iE6NFqc7Cpb_white-space);
}
