.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background: none;
  min-width: 0;
  padding: 0px 20px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    flex-wrap: wrap;
    align-items: stretch;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.freeBox__tDFzc {
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 0px;
  min-width: 0;
  padding: 50px 0px 0px;
}
.freeBox__tDFzc > :global(.__wab_flex-container) {
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 5px);
  width: calc(100% + 5px);
}
.freeBox__tDFzc > :global(.__wab_flex-container) > *,
.freeBox__tDFzc > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__tDFzc > :global(.__wab_flex-container) > picture > img,
.freeBox__tDFzc
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 5px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__tDFzc {
    padding: 16px 10px;
  }
}
.menuButton:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
  display: none;
}
@media (min-width: 0px) and (max-width: 768px) {
  .menuButton:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.menuButtonexpanded:global(.__wab_instance) {
  flex-shrink: 0;
}
@media (min-width: 0px) and (max-width: 768px) {
  .menuButtonexpanded:global(.__wab_instance) {
    flex-shrink: 0;
  }
}
.link__pesX4 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 0px;
}
.img {
  position: relative;
  object-fit: fill;
  max-width: 100%;
  width: 120px;
  flex-shrink: 0;
}
.img > picture > img {
  object-fit: fill;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: fill;
}
.freeBox__xOQym {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__xOQym > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 50px);
  width: calc(100% + 50px);
}
.freeBox__xOQym > :global(.__wab_flex-container) > *,
.freeBox__xOQym > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__xOQym > :global(.__wab_flex-container) > picture > img,
.freeBox__xOQym
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 50px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__xOQym {
    display: flex;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__xOQym > :global(.__wab_flex-container) {
    margin-left: calc(0px - 15px);
    width: calc(100% + 15px);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBox__xOQym > :global(.__wab_flex-container) > *,
  .freeBox__xOQym > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .freeBox__xOQym > :global(.__wab_flex-container) > picture > img,
  .freeBox__xOQym
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 15px;
    margin-top: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .freeBoxexpanded__xOQymx7Fpy {
    display: none;
  }
}
.link__bhs1R {
  color: var(--token-X1jDnyWGByQLrV);
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link__bhs1R {
    font-size: 16px;
  }
}
.link__ncxfd {
  color: var(--token-X1jDnyWGByQLrV);
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link__ncxfd {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--token-X1jDnyWGByQLrV);
  }
}
.link___9Ts33 {
  color: var(--token-X1jDnyWGByQLrV);
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  position: relative;
}
@media (min-width: 0px) and (max-width: 768px) {
  .link___9Ts33 {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: var(--token-X1jDnyWGByQLrV);
  }
}
.freeBox__kKv5C {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: none;
}
.linkButton__cbGe:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.linkButton__fnH9F:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.linkButton__j7Krk:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.linkButton__gNyCl:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
.linkButton__ipGmr:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
