.root {
  display: flex;
  width: 32px;
  height: 32px;
  position: relative;
  padding: 0px;
  border-width: 0px;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
  border-radius: 5px;
  border: 1px solid rgb(233, 156, 46);
}
.svg___92Bkq {
  position: relative;
  object-fit: cover;
  color: rgb(233, 156, 46);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.svgexpanded___92Bkq8Ewto {
  display: none;
}
.svg__lvcQt {
  position: relative;
  object-fit: cover;
  color: rgb(233, 156, 46);
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: none;
}
