.root {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100vw;
}
.freeBox__s14UI {
  display: flex;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  padding-top: 128px;
  padding-bottom: 128px;
  background: url("./images/tableBanner.jpg") 0% 0% / cover;
  min-height: 0;
}
.freeBox__j0Rw {
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 33%;
  opacity: 0.95;
  background: var(--token-QQdIvJFj1);
  min-height: 0;
}
.freeBox__lu7W {
  max-width: 1140px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  min-width: 0;
}
.freeBox__nsDlO {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 720px;
  min-width: 0;
  padding: 32px 24px;
}
.freeBox__nsDlO > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox__nsDlO > :global(.__wab_flex-container) > *,
.freeBox__nsDlO > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nsDlO > :global(.__wab_flex-container) > picture > img,
.freeBox__nsDlO
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.text__t0Esc {
  position: relative;
  color: var(--token-X1jDnyWGByQLrV);
  font-size: 24px;
  font-weight: 700;
}
.text__tKdc {
  position: relative;
  color: var(--token-X1jDnyWGByQLrV);
}
.freeBox__uxMwf {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.text__zlw88 {
  color: var(--token-X1jDnyWGByQLrV);
  font-size: 14px;
}
.text__lTc7G {
  position: relative;
  color: rgb(233, 156, 46);
}
.freeBox__pp0T {
  display: flex;
  position: relative;
  flex-direction: row;
}
.freeBox__pp0T > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.freeBox__pp0T > :global(.__wab_flex-container) > *,
.freeBox__pp0T > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__pp0T > :global(.__wab_flex-container) > picture > img,
.freeBox__pp0T
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 8px;
}
.linkButton:global(.__wab_instance) {
  position: relative;
  flex-shrink: 0;
}
