.root {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  position: relative;
  min-width: 0;
  padding: 8px 8px 0px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 0;
  margin-left: calc(0px - 15px);
  width: calc(100% + 15px);
  --plsmc-rc-col-gap: 15px;
  margin-top: calc(0px - 15px);
  height: calc(100% + 15px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 15px;
  margin-top: 15px;
}
.root {
  --plsmc-rc-col-gap: 0px;
}
.root > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.root > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.root > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
.root > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * var(--plsmc-rc-col-gap, 0px)) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 768px) {
  .root {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    min-width: 0;
    margin-top: calc(0px - 32px);
    height: calc(100% + 32px);
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) > *,
  .root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .root > :global(.__wab_flex-container) > picture > img,
  .root
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 32px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
  }
}
.column___3GvG7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column___3GvG7 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column___3GvG7 > :global(.__wab_flex-container) > *,
.column___3GvG7 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column___3GvG7 > :global(.__wab_flex-container) > picture > img,
.column___3GvG7
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__uVhj4 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  height: var(--token-PE1QFaZ5ZFDg60);
  width: 100%;
  object-position: 50% -18px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__uVhj4 > picture > img {
  object-fit: cover;
  object-position: 50% -18px;
}
.img__uVhj4 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% -18px;
}
@media (min-width: 0px) and (max-width: 768px) {
  .img__uVhj4 {
    margin-top: calc(56px + 16px) !important;
    margin-bottom: 0px;
  }
}
.freeBox__xtKNe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__epi81 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__rb9B6 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.column__fsOoG {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__fsOoG > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__fsOoG > :global(.__wab_flex-container) > *,
.column__fsOoG > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__fsOoG > :global(.__wab_flex-container) > picture > img,
.column__fsOoG
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__cHkyl {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: -4px -32px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__cHkyl > picture > img {
  object-fit: cover;
  object-position: -4px -32px;
}
.img__cHkyl > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: -4px -32px;
}
.freeBox__kFk2E {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__wyx0N {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__eUhs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  padding-top: 0px;
  min-width: 0;
}
.column__hk3Fs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__hk3Fs > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__hk3Fs > :global(.__wab_flex-container) > *,
.column__hk3Fs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__hk3Fs > :global(.__wab_flex-container) > picture > img,
.column__hk3Fs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__alQhY {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: 50% -38px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__alQhY > picture > img {
  object-fit: cover;
  object-position: 50% -38px;
}
.img__alQhY > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% -38px;
}
.freeBox__qNiyW {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__zaEMf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__y8Kbn {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
.column__gzjVs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
}
.column__gzjVs > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.column__gzjVs > :global(.__wab_flex-container) > *,
.column__gzjVs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.column__gzjVs > :global(.__wab_flex-container) > picture > img,
.column__gzjVs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 16px;
}
.img__fovXc {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  box-shadow: 0px 5px 16px 0px #00000040;
  width: 100%;
  height: var(--token-PE1QFaZ5ZFDg60);
  object-position: 50% -53px;
  min-width: 0;
  flex-shrink: 0;
  border-radius: 24px;
}
.img__fovXc > picture > img {
  object-fit: cover;
  object-position: 50% -53px;
}
.img__fovXc > :global(.__wab_img-spacer) > img {
  object-fit: cover;
  object-position: 50% -53px;
}
.freeBox__yNoDe {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.link__y9QDr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-h6aVhKAyca-qac);
  font-size: 24px;
  min-width: 0;
}
.text__u3IAg {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--token-h6aVhKAyca-qac);
  min-width: 0;
}
