.root {
  display: flex;
  width: 100%;
  height: 412px;
  position: relative;
  flex-direction: column;
  max-width: 260px;
  overflow: hidden;
  background: var(--token-X1jDnyWGByQLrV);
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10px;
}
.freeBox__ukVs {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 300px;
  background: var(--token-kvivt7Cu3craYW);
  min-width: 0;
  flex-shrink: 0;
}
.img__s5A1 {
  position: relative;
  object-fit: cover;
  width: 100%;
  min-width: 0;
}
.img__s5A1 > picture > img {
  object-fit: cover;
}
.img__s5A1 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox__o79Uz {
  position: absolute;
  left: auto;
  top: 20px;
  right: 0px;
  height: 30px;
  width: 60px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: rgb(233, 156, 46);
  flex-shrink: 0;
  display: none;
}
.text__p2EqJ {
  position: relative;
  color: var(--token-X1jDnyWGByQLrV);
}
.freeBox___0AJAb {
  position: absolute;
  flex-direction: row;
  left: 0px;
  top: auto;
  width: 100%;
  bottom: 0px;
  height: 35px;
  background: var(--token-6-9k9VEZ-);
  min-width: 0;
  display: none;
  padding: 10px;
}
.freeBox___0AJAb > :global(.__wab_flex-container) {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.freeBox___0AJAb > :global(.__wab_flex-container) > *,
.freeBox___0AJAb > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox___0AJAb > :global(.__wab_flex-container) > picture > img,
.freeBox___0AJAb
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.svg___8SJmv {
  position: relative;
  object-fit: cover;
  color: var(--token-X1jDnyWGByQLrV);
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.text__crLy {
  position: relative;
  width: 100%;
  color: var(--token-X1jDnyWGByQLrV);
  min-width: 0;
}
.svg___10Jgk {
  position: relative;
  object-fit: cover;
  color: var(--token-X1jDnyWGByQLrV);
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.svg__pt44O {
  position: relative;
  object-fit: cover;
  color: var(--token-X1jDnyWGByQLrV);
  width: 1em;
  flex-shrink: 0;
  height: 1em;
}
.ratings__nz1TR:global(.__wab_instance) {
  position: relative;
}
.freeBox__uBnQy {
  display: flex;
  position: relative;
  flex-direction: column;
}
.freeBox__uBnQy > :global(.__wab_flex-container) {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.freeBox__uBnQy > :global(.__wab_flex-container) > *,
.freeBox__uBnQy > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__uBnQy > :global(.__wab_flex-container) > picture > img,
.freeBox__uBnQy
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.slotTargetTitle {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: var(--token-0DdnGTX5B6AWcm);
}
.slotTargetPrice {
  color: var(--token-0DdnGTX5B6AWcm);
  text-align: center;
  font-size: 18px;
}
